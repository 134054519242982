<template>
    <v-component
        :is="FormElementComponent"
        :id="id"
        :form-element-component="SelfComponent"
        :currency="currency"
        :custom-component-input="customComponents"
        :form="form"
        :value="value"
        :depth="depth"
        :description="description"
        :evaluation-errors="evaluationErrors"
        :start-index="startIndex"
        v-on="$listeners"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import JsonForm, { JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import JsonSchemaArrayView from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaArrayView.vue';
    import JsonSchemaStringView
        from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaStringView.vue';
    import JsonSchemaTextView from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaTextView.vue';
    import JsonSchemaBooleanView
        from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaBooleanView.vue';
    import JsonSchemaObjectView
        from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaObjectView.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import JsonSchemaNumberView
        from '@/modules/insured-details/components/JsonSchemaView/elements/JsonSchemaNumberView.vue';

    const elementByType: Record<JsonFormType, Vue.Component> = {
        [JsonFormType.array]: JsonSchemaArrayView,
        [JsonFormType.string]: JsonSchemaStringView,
        [JsonFormType.number]: JsonSchemaNumberView,
        [JsonFormType.boolean]: JsonSchemaBooleanView,
        [JsonFormType.object]: JsonSchemaObjectView,
        [JsonFormType.const]: JsonSchemaTextView,
        [JsonFormType.enum]: JsonSchemaTextView,
    };

    @Component({
        inheritAttrs: false,
    })
    export default class JsonSchemaViewElement extends Vue {
        private SelfComponent = JsonSchemaViewElement;

        @Prop(Object)
        private form!: JsonForm;

        @Prop()
        private value!: any;

        @Prop({ type: String, default: '' })
        private description!: string;

        @Prop({ type: Number, default: 0 })
        private depth!: number;

        @Prop(String)
        private id!: string;

        @Prop({ type: Object, default: () => ({}) })
        private customComponents!: Partial<Record<JsonFormType, Vue.Component>>;

        @Prop({ type: Object, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: String, default: 'USD' })
        private currency!: string;

        @Prop({ type: Number, default: 1 })
        private startIndex !: number;

        private get FormElementComponent() {
            return this.customComponents[this.form.type] || elementByType[this.form.type];
        }
    }
</script>
