<template>
    <div class="MultiFieldCriterion">
        <div class="MultiFieldCriterion__endorsement">
            <span class="MultiFieldCriterion__index" :class="additionalIndexClass">
                {{ index }}
            </span>
            <div class="MultiFieldCriterion__body">
                <div class="MultiFieldCriterion__header">
                    <span class="MultiFieldCriterion__headerName">
                        {{ criterion.name }}
                    </span>
                    <EvaluationError
                        v-if="criterion.evaluationError"
                        :evaluation-error="criterion.evaluationError"
                        v-on="$listeners"
                    />
                </div>
                <div class="MultiFieldCriterion__records">
                    <div
                        v-for="[key, value] in Object.entries(criterion.fields)"
                        :key="key"
                        class="MultiFieldCriterion__record"
                        :data-test-id="`MultiFieldCriterion__${key}Record`"
                    >
                        <div class="MultiFieldCriterion__title">
                            {{ startCase(key) }}
                        </div>
                        <div class="MultiFieldCriterion__valueWithError">
                            <div class="MultiFieldCriterion__value">
                                {{ getFieldValue(value) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
    import startCase from 'lodash/startCase';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';

    export default Vue.extend({
        name: 'MultiFieldCriterion',
        components: {
            EvaluationError,
        },
        props: {
            criterion: {
                type: Object as PropType<InsuredMultiFieldCriterion>,
                default: () => ({}) as InsuredMultiFieldCriterion,
            },
            index: {
                type: Number as PropType<number>,
                default: 1,
            },
        },
        computed: {
            additionalIndexClass(): string | null {
                if (!this.criterion?.evaluationError) {
                    return null;
                }
                return this.criterion.evaluationError.complianceStatusMetadata.length > 0
                    ? 'MultiFieldCriterion--exception'
                    : 'MultiFieldCriterion--error';
            },
        },
        methods: {
            getFieldValue(value: unknown): unknown {
                if (Array.isArray(value)) {
                    return value.join(', ');
                }

                return value;
            },
            startCase,
        },
    });
</script>
