import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';

export function getEnumDefaultDisplayValue<T>(
    enumCategory: string | EnumCategories,
    enumValue: T,
    readableEnums: CategorizedEnumLabels,
): T | string | string[] {
    const enumLabels = readableEnums[enumCategory];

    if (!enumLabels) {
        return enumValue;
    }

    if (Array.isArray(enumValue)) {
        return enumValue.map((val) => enumLabels[val]?.label || val);
    }

    return enumLabels[enumValue as any]?.label || enumValue;
}
