<template>
    <div class="CriterionTextInput">
        <FormElement :label="hideTitle ? null : form.schema.title">
            <template v-if="form.schema.description" v-slot:labelHint>
                <div v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot>
                <input
                    type="text"
                    :value="value"
                    :maxlength="form.schema.maxLength || 1024"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @change="onChange"
                >
                <template v-if="(showError || accessed) && error" v-slot:errorMessage>
                    {{ error }}
                </template>
                <template v-if="deletable" v-slot:actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            alt="delete"
                            :icon-src="tranIcon"
                            :svg-props="{color: alertRedColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import tranIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CoverageCriterionInputComponents/AbstractCriterionInput';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import trim from 'lodash/trim';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: { EidIcon, FormInput, FontAwesomeIcon, FormElement },
    })
    export default class CriterionTextInput extends AbstractCriterionInput<JsonFormBase<JsonSchemaString>, string> {
        private tranIcon = tranIcon;
        private faQuestionCircle = faQuestionCircle;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        protected override get empty(): boolean {
            return this.form.isEmpty(this.form.getValue(trim(this.value)));
        }

        protected override get valid(): boolean {
            return this.form.isValid(this.form.getValue(trim(this.value)));
        }

        protected override get error(): string | null {
            if (this.empty && !this.valid) {
                return this.translate('error.required');
            }
            return this.valid ? null : this.getCustomError() || this.translate('error.unknown');
        }

        private onInput(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', this.form.getValue(inputValue));
        }

        private onChange(event: Event): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('change', this.form.getValue(inputValue));
        }
    }
</script>
