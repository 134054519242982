<template>
    <div class="ActionReviewDetails">
        <NavigationalHeader
            :title="value"
            :current-position="currentPosition"
            :max-position="maxPosition"
            :title-prefix="actionTypeTitlePrefix"
            v-on="$listeners"
        />
        <EntityNameMatching
            v-if="actionType === InsuranceInsuredActionType.entityNameMatchingV1"
            :action-details="actionDetails"
            @resolve="resolve"
        />
        <EndorsementReview
            v-else-if="actionType === InsuranceInsuredActionType.endorsementFormNumberV1"
            :action-details="actionDetails"
            :endorsement-categories="endorsementCategories"
            @resolve="resolve"
        />
        <EvaluatedEntityMappingReview
            v-else-if="actionType === InsuranceInsuredActionType.evaluatedEntityMappingV1"
            :action-details="actionDetails"
            @resolve="resolve"
        />
        <NonExtractionReview
            v-else-if="actionType === InsuranceInsuredActionType.nonExtractionCriterionResolutionV1"
            :action-details="actionDetails"
            @resolve="resolve"
            @cancel="$emit('cancel')"
        />
        <ComplianceWithCategoryResolutionReview
            v-else-if="actionType === InsuranceInsuredActionType.complianceWithCategoryResolution"
            :action-details="actionDetails"
            @resolve="resolve"
        />
    </div>
</template>
<style lang="scss">
    .ActionReviewDetails {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceActionResolveInput,
        InsuranceInsuredActionDetails,
        InsuranceInsuredActionType,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import EntityNameMatching
        from '@/modules/insured-actions-review/components/EntityNameMatching/EntityNameMatching.vue';
    import NavigationalHeader
        from '@/modules/insured-actions-review/components/NavigationalHeader/NavigationalHeader.vue';
    import { getActionValue } from '@/modules/insured-actions-review/utils/getActionValue';
    import EndorsementReview from '@/modules/insured-actions-review/components/EndorsementReview/EndorsementReview.vue';
    import ActionableAlert from '@/modules/insured-details/components/ActionableAlert/ActionableAlert.vue';
    import EvaluatedEntityMappingReview
        from '@/modules/insured-actions-review/components/EvaluatedEntityMappingReview/EvaluatedEntityMappingReview.vue';
    import NonExtractionReview
        from '@/modules/insured-actions-review/components/NonExtractionReview/NonExtractionReview.vue';
    import ComplianceWithCategoryResolutionReview
        from '@/modules/insured-actions-review/components/ComplianceWithCategoryResolutionReview/ComplianceWithCategoryResolutionReview.vue';

    @Component({
        components: {
            ComplianceWithCategoryResolutionReview,
            NonExtractionReview,
            ActionableAlert,
            EndorsementReview,
            EntityNameMatching,
            EvaluatedEntityMappingReview,
            NavigationalHeader,
        },
    })
    export default class ActionReviewDetails extends Vue {
        @Prop({ type: Object })
        private actionDetails!: InsuranceInsuredActionDetails;

        @Prop({ type: Number, default: 0 })
        private currentPosition!: number;

        @Prop({ type: Number, default: 0 })
        private maxPosition!: number;

        @Prop({ type: Array, default: () => [] })
        private endorsementCategories!: string[];

        private InsuranceInsuredActionType = InsuranceInsuredActionType;

        private get actionType(): InsuranceInsuredActionType {
            return this.actionDetails.action.$action;
        }

        private get actionTypeTitlePrefix(): string {
            // [entityMappingExpansion]: only collateral entity atm, get different text per type when expanded
            return this.actionDetails.action.$action === InsuranceInsuredActionType.evaluatedEntityMappingV1
                ? 'Collateral'
                : '';
        }

        private get value(): any {
            return getActionValue(this.actionDetails);
        }

        public resolve(data: InsuranceActionResolveInput): void {
            this.$emit('resolve', data);
        }
    }
</script>
