<template>
    <Modal :additional-class-name="className" allow-close open @close="$emit('close')">
        <template #header>
            Grant an Exception
        </template>
        <div data-test-id="ExceptionExpirationModal__content">
            <div data-test-id="ExceptionExpirationModal__description">
                Please choose when you would like the exception to expire. An exception can temporarily or permanently
                waive non-compliant criteria depending on the settings selected. Exceptions can be undone.
            </div>
            <div
                data-test-id="ExceptionExpirationModal__exceptionTypeContainer"
                class="ExceptionExpirationModal__exceptionTypeContainer"
            >
                <span class="ExceptionExpirationModal__selectionLabel">Expiration Type:</span>
                <Dropdown
                    data-test-id="ExceptionExpirationModal__exceptionTypeDropdown"
                    :options="exceptionTypeOptions"
                    :disabled-values="disabledOptions"
                    :disabled="loading"
                    @input="onTypeSelection"
                />
            </div>
            <div
                v-if="selectedType === InsuranceExceptionType.untilDate ||
                    selectedType === InsuranceExceptionType.untilCoverageExpiration"
                data-test-id="ExceptionExpirationModal__exceptionTypeBasedContent"
                class="ExceptionExpirationModal__exceptionTypeBasedContent"
            >
                <div
                    v-if="selectedType === InsuranceExceptionType.untilDate"
                    data-test-id="ExceptionExpirationModal__selectDateRow"
                    class="flex items-center"
                >
                    <div>Exception will expire on</div>
                    <DatePicker
                        data-test-id="ExceptionExpirationModal__selectDatePicker"
                        class="mx-3"
                        placeholder="Select Date"
                        :disabled="loading"
                        :min-date="tomorrowIsoString"
                        @input="onExpirationDateSelection"
                    />
                </div>
                <div v-else-if="selectedType === InsuranceExceptionType.untilCoverageExpiration" class="text-center">
                    The exception granted will expire on the coverage type expiration date of
                    <strong>{{ coverageExpiration }}</strong>. The
                    exception will expire prior to the date listed above if new policy data is added.
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                :loading="loading"
                data-test-id="ExceptionExpirationModal__grantButton"
                class="ExceptionExpirationModal__grantButton"
                type="primary"
                :disabled="disableGrant"
                @click="grantException"
            >
                Grant Exception
            </Button>
        </template>
    </Modal>
</template>

<style lang="scss">
    @import "./index";
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import {
        InsuranceExceptionInput,
        InsuranceExceptionLevel,
        InsuranceExceptionType,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { InsuranceInsuredCoverage } from '@evidentid/rpweb-api-client/types';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { DatePicker } from '@evidentid/dashboard-commons/components/DatePicker';

    type ExceptionTypeDropdownOption = DropdownOption<InsuranceExceptionType>;

    const componentName = 'ExceptionExpirationModal';
    export default Vue.extend({
        name: componentName,
        components: {
            Button,
            Modal,
            Dropdown,
            DatePicker,
        },
        props: {
            loading: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            additionalClassName: {
                type: String as PropType<string>,
                default: '',
            },
            exceptionInputs: {
                type: Array as PropType<InsuranceExceptionInput[]>,
                default: () => [],
            },
            coverage: {
                type: Object as PropType<InsuranceInsuredCoverage | null>,
                default: null,
            },
        },
        data() {
            return {
                selectedType: null as InsuranceExceptionType | null,
                selectedExpirationDate: null as string | null,
                exceptionTypeOptions: [
                    { value: InsuranceExceptionType.untilDate, label: 'Expire after selected calendar date' },
                    {
                        value: InsuranceExceptionType.untilCoverageExpiration,
                        label: 'Expires on current policy expiration or updated policy',
                    },
                    { value: InsuranceExceptionType.permanent, label: 'Permanent - Does not expire' },
                ] as ExceptionTypeDropdownOption[],
                InsuranceExceptionType,
            };
        },
        computed: {
            disableGrant(): boolean {
                return !this.selectedType || this.isInvalidUntilDateType;
            },
            isInvalidUntilDateType(): boolean {
                return this.selectedType === InsuranceExceptionType.untilDate && this.selectedExpirationDate == null;
            },
            disabledOptions(): InsuranceExceptionType[] {
                const isInsuredLevel =
                    this.exceptionInputs.length === 1 &&
                    this.exceptionInputs[0].level === InsuranceExceptionLevel.insured;
                const todayIsoString = new Date().toISOString().split('T')[0];
                const todayDate = new Date(todayIsoString);
                const coverageExpiredOrNull = !this.coverage || Object.keys(this.coverage).length === 0
                    || new Date(this.coverage?.policy.expirationDate) <= todayDate;
                return isInsuredLevel || coverageExpiredOrNull
                    ? [ InsuranceExceptionType.untilCoverageExpiration ]
                    : [];
            },
            className(): string {
                const additionalClassName = this.additionalClassName ? ` ${this.additionalClassName}` : '';
                return `${componentName}${additionalClassName}`;
            },
            coverageExpiration(): string {
                return this.coverage && this.reformatDateStringToUsDate(this.coverage.policy.expirationDate) || '';
            },
            tomorrowIsoString(): string {
                const today = new Date();
                const tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                return tomorrow.toISOString().split('T')[0];
            },
        },
        methods: {
            onTypeSelection(option: ExceptionTypeDropdownOption): void {
                this.selectedType = option.value;
                if (this.selectedType !== InsuranceExceptionType.untilDate) {
                    this.selectedExpirationDate = null;
                }
            },
            onExpirationDateSelection(date: string): void {
                this.selectedExpirationDate = date;
            },
            grantException(): void {
                if (this.disableGrant) {
                    return;
                }
                const moddedExceptions = this.exceptionInputs.map((input) => ({
                    ...input,
                    type: this.selectedType,
                    until: this.selectedExpirationDate,
                }));
                this.$emit('grant-exception', moddedExceptions);
            },
            reformatDateStringToUsDate(isoString: string): string {
                // expirationDate from coverage.policy does not include timezone so construct date as local date
                const parts = isoString.split('-');
                // part 1 -1 because month starts in 0 while iso string start 1.
                const date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
                return new Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }).format(date);
            },
        },
    });
</script>
