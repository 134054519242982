<template>
    <div class="EvaluatedEntityMappingReview">
        <div class="EvaluatedEntityMappingReview__container">
            <div class="EvaluatedEntityMappingReview__form">
                <Table class="EvaluatedEntityMappingReview__table">
                    <template #head>
                        <HeadCell class="EvaluatedEntityMappingReview__requiredCollateralHeader">
                            Required Collateral
                        </HeadCell>
                        <HeadCell class="EvaluatedEntityMappingReview__providedCollateralHeader">
                            Provided Collateral
                        </HeadCell>
                        <HeadCell class="EvaluatedEntityMappingReview__noMatchHeader" />
                    </template>
                    <Row hoverable>
                        <Cell class="EvaluatedEntityMappingReview__requiredCollateral">
                            {{ requiredEntity.description }}
                        </Cell>
                        <Cell class="EvaluatedEntityMappingReview__providedCollateral">
                            <Dropdown
                                :placeholder="dropdownPlaceholder"
                                :options="options"
                                :selected="selectedOptions"
                                :disabled="noMatch"
                                @input="onCollateralSelection"
                            />
                        </Cell>
                        <Cell class="EvaluatedEntityMappingReview__noMatch">
                            <Checkbox
                                class="EvaluatedEntityMappingReview__noMatch"
                                @input="toggleNoMatch"
                            >
                                No Match
                            </Checkbox>
                        </Cell>
                    </Row>
                </Table>
                <Button
                    class="EvaluatedEntityMappingReview__saveButton float-right"
                    type="primary"
                    :disabled="saveDisabled"
                    @click="onSave"
                >
                    Save
                </Button>
            </div>

            <div class="EvaluatedEntityMappingReview__documents">
                <LogoLoader v-if="loading" />
                <DocumentsViewer v-if="!loading" :documents="docsStatus.list" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import BinaryDataDisplay from '@/components/attributes/BinaryDataDisplay.vue';
    import {
        InsuranceEvaluatedCollateralEntity,
        InsuranceEntityMappingProvidedEntityDetails,
        InsuranceEvaluatedEntity,
        InsuranceEvaluatedEntityMappingActionDetails,
        InsuranceInsuredActionType,
        InsuranceEvaluatedEntityMappingResolveInput,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import DocumentsViewer from '@/modules/insured-actions-review/components/DocumentsViewer/DocumentsViewer.vue';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import EmptyValueDashSymbol
        from '@/modules/insured-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';
    import { CoiDocumentStatus } from '@/modules/insured-actions-review/vuex';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';

    @Component({
        components: {
            ConfirmationModal,
            EmptyValueDashSymbol,
            Button,
            BinaryDataDisplay,
            Checkbox,
            Dropdown,
            DocumentsViewer,
            HeadCell,
            LogoLoader,
            Table,
            Cell,
            Row,
        },
    })
    export default class EvaluatedEntityMappingReview extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private actionDetails!: InsuranceEvaluatedEntityMappingActionDetails;

        private noMatch: boolean = false;
        private selectedOptions: DropdownOption[] = [];
        private options: DropdownOption[] = [];

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get docsStatus(): CoiDocumentStatus {
            if (this.$store.state.insuredActionsReview.coiDocuments[this.rpName] &&
                this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id]) {
                return this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id];
            } else {
                return { status: OperationStatus.uninitialized, list: [] };
            }
        }

        private get loading(): boolean {
            return this.docsStatus.status === OperationStatus.loading;
        }

        private get saveDisabled(): boolean {
            return this.selectedOptions.length === 0 && !this.noMatch;
        }

        private get dropdownPlaceholder(): string {
            return this.noMatch ? 'N/A' : 'Choose Mapping';
        }

        private get requiredEntity(): InsuranceEvaluatedEntity {
            return this.actionDetails.action.requiredEntity;
        }

        private get providedEntityDetails(): InsuranceEntityMappingProvidedEntityDetails[] {
            return this.actionDetails.action.providedEntities;
        }

        @Watch('actionDetails', { immediate: true })
        private onActionDetailsChange(): void {
            // [entityMappingExpansion]: only collateral entity atm, get different options per type when expanded
            this.options = this.constructProvidedCollateralOptions();
            if (this.docsStatus.status === OperationStatus.uninitialized) {
                const rprIds = this.providedEntityDetails.map((x) => x.relyingPartyRequestId);
                const uniqueRprIds = [ ...new Set(rprIds) ];
                this.$store.actions.insuredActionsReview.loadCoiDocuments({
                    rpName: this.rpName,
                    actionId: this.actionDetails.id,
                    rprIds: uniqueRprIds,
                });
            }
        }

        private constructProvidedCollateralOptions(): DropdownOption<InsuranceEvaluatedCollateralEntity>[] {
            return this.providedEntityDetails.map((details) => {
                const entity = (details.providedEntity as InsuranceEvaluatedCollateralEntity);
                const notProvided = 'No description/identifier provided';
                const separator = entity.description && entity.uniqueIdentifier ? ' - ' : '';
                const label = !entity.description && !entity.uniqueIdentifier
                    ? notProvided
                    : `${entity.description || ''}${separator}${entity.uniqueIdentifier || ''}`;
                return {
                    value: entity,
                    label,
                };
            });
        }

        private toggleNoMatch(checked: boolean): void {
            this.noMatch = checked;
            if (checked) {
                this.selectedOptions = [];
            }
        }

        private onCollateralSelection(option: DropdownOption): void {
            this.selectedOptions = [ option ];
        }

        private onSave(): void {
            const mappingResolution: InsuranceEvaluatedEntityMappingResolveInput = {
                $action: InsuranceInsuredActionType.evaluatedEntityMappingV1,
                accepted: this.selectedOptions.length > 0 && !this.noMatch,
                ...(this.selectedOptions[0] && { providedEntity: this.selectedOptions[0].value }),
            };
            this.$emit('resolve', mappingResolution);
        }
    }
</script>
