import startCase from 'lodash/startCase';
import {
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionMatchType,
    InsuranceInsuredActionType,
    InsuranceInsuredEntityMatchingActionDetails,
    InsuranceNonExtractionResolutionActionDetails,
    InsuranceComplianceWithCategoryResolutionActionDetails,
} from '@evidentid/insurance-facing-lib/models/insured-actions-review';

function extractFieldName(field: string): string {
    const tokens = field.split('.');
    return tokens[tokens.length - 1];
}

export function getEntityMatchFieldName(insuredAction: InsuranceInsuredEntityMatchingActionDetails): string {
    switch (insuredAction.action.matchType) {
        case InsuranceInsuredActionMatchType.insured:
            return 'Insured Name';
        case InsuranceInsuredActionMatchType.relyingParty:
            return 'Relying Party';
        case InsuranceInsuredActionMatchType.custom:
            return extractFieldName(insuredAction.action.field);
    }
}

function getEntityNameMatching(actionDetails: InsuranceInsuredActionDetails) {
    const prefix = 'Entity Name Matching';
    const fieldNameLabel =
        startCase(getEntityMatchFieldName(actionDetails as InsuranceInsuredEntityMatchingActionDetails));
    const hyphen = fieldNameLabel ? ' - ' : '';
    return `${prefix}${hyphen}${fieldNameLabel}`;
}

function getNonExtractionCriterionResolution(actionDetails: InsuranceInsuredActionDetails) {
    return (actionDetails as InsuranceNonExtractionResolutionActionDetails).action.field === 'exclusions'
        ? 'Exclusion Review'
        : 'Project Requirement Review';
}

function getComplianceWithCategoryResolution(actionDetails: InsuranceInsuredActionDetails) {
    const fieldName = extractFieldName(
        (actionDetails as InsuranceComplianceWithCategoryResolutionActionDetails).action.field
    );
    const fieldNameLabel = startCase(fieldName);
    return `Form Number Matching - ${fieldNameLabel}`;
}

const actionPrefixMapping: Record<
    InsuranceInsuredActionType,
    ((actionDetails: InsuranceInsuredActionDetails) => string)
> = {
    [InsuranceInsuredActionType.endorsementFormNumberV1]: () => 'Endorsement Review',
    [InsuranceInsuredActionType.evaluatedEntityMappingV1]: () => 'Collateral mapping',
    [InsuranceInsuredActionType.entityNameMatchingV1]: getEntityNameMatching,
    [InsuranceInsuredActionType.nonExtractionCriterionResolutionV1]: getNonExtractionCriterionResolution,
    [InsuranceInsuredActionType.complianceWithCategoryResolution]: getComplianceWithCategoryResolution,
};

export function getActionRequired(insuredAction: InsuranceInsuredActionDetails): string {
    const actionType: InsuranceInsuredActionType = insuredAction.action.$action;
    return actionPrefixMapping[actionType]?.(insuredAction) || '';
}
