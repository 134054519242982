<template>
    <div class="EndorsementReview">
        <ConfirmationModal
            v-if="showConfirmationModal"
            additional-class-name="EndorsementReview__confirmationModal"
            title="Save Action Review"
            :yes-icon="null"
            :no-icon="null"
            @input="onModalAction"
        >
            Future global actions will be automatically reviewed based on your review. Saving this action cannot be
            undone.
        </ConfirmationModal>
        <div class="EndorsementReview__container">
            <div class="EndorsementReview__form">
                <Table class="EndorsementReview__table">
                    <template #head>
                        <HeadCell class="EndorsementReview__fieldHeader">
                            Field
                        </HeadCell>
                        <HeadCell class="EndorsementReview__valueHeader">
                            Value
                        </HeadCell>
                    </template>
                    <Row hoverable>
                        <Cell class="EndorsementReview__fieldHeader">
                            Form Number
                        </Cell>
                        <Cell class="EndorsementReview__valueHeader">
                            <span v-if="actionDetails.action.formNumber">
                                {{ actionDetails.action.formNumber }}
                            </span>
                            <EmptyValueDashSymbol v-else />
                        </Cell>
                    </Row>
                    <Row hoverable>
                        <Cell class="EndorsementReview__fieldHeader">
                            Endorsement Category
                        </Cell>
                        <Cell class="EndorsementReview__valueHeader">
                            <Dropdown
                                :placeholder="dropdownPlaceholder"
                                :options="options"
                                :selected="selectedOptions"
                                :disabled="categoryNotListed"
                                @input="onCategoriesSelection"
                            />
                            <Checkbox class="EndorsementReview__categoryNotListed" @input="setCategoryNotFoundStatus">
                                Additional Insured Endorsement, Category not listed
                            </Checkbox>
                        </Cell>
                    </Row>
                </Table>
                <Button
                    class="EndorsementReview__saveButton"
                    type="primary"
                    :disabled="saveDisabled"
                    @click="onSave"
                >
                    Save
                </Button>
            </div>

            <div class="EndorsementReview__documents">
                <LogoLoader v-if="loading" />
                <DocumentsViewer v-if="!loading" :documents="docsStatus.list" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import BinaryDataDisplay from '@/components/attributes/BinaryDataDisplay.vue';
    import { CoiDocumentStatus } from '@/modules/insured-actions-review/vuex';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import {
        InsuranceEndorsementActionResolveInput,
        InsuranceInsuredActionType,
        InsuranceInsuredEndorsementActionDetails,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import DocumentsViewer from '@/modules/insured-actions-review/components/DocumentsViewer/DocumentsViewer.vue';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import EmptyValueDashSymbol
        from '@/modules/insured-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';

    @Component({
        components: {
            ConfirmationModal,
            EmptyValueDashSymbol,
            Button,
            BinaryDataDisplay,
            Checkbox,
            Dropdown,
            DocumentsViewer,
            HeadCell,
            LogoLoader,
            Table,
            Cell,
            Row,
        },
    })
    export default class EndorsementReview extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private actionDetails!: InsuranceInsuredEndorsementActionDetails;

        @Prop({ type: Array, default: () => [] })
        private endorsementCategories!: string[];

        private categoryNotListed: boolean = false;
        private selectedOptions: DropdownOption[] = [];
        private options: DropdownOption[] = [];
        private showConfirmationModal: boolean = false;

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get docsStatus(): CoiDocumentStatus {
            if (this.$store.state.insuredActionsReview.coiDocuments[this.rpName] &&
                this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id]) {
                return this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id];
            } else {
                return { status: OperationStatus.uninitialized, list: [] };
            }
        }

        private get loading(): boolean {
            return this.docsStatus.status === OperationStatus.loading;
        }

        private get saveDisabled(): boolean {
            return this.selectedOptions.length === 0 && !this.categoryNotListed;
        }

        private get dropdownPlaceholder(): string {
            return this.categoryNotListed ? 'N/A' : 'Select Category';
        }

        @Watch('endorsementCategories', { immediate: true })
        private onCategoriesChange(): void {
            this.options = [ ...this.endorsementCategories ].sort().map((cat) => ({ label: cat, value: cat }));
        }

        @Watch('actionDetails', { immediate: true })
        private onActionDetailsChange(): void {
            if (this.docsStatus.status === OperationStatus.uninitialized &&
                this.actionDetails.evaluationResults.length > 0 &&
                this.actionDetails.evaluationResults[0].verificationId) {
                this.$store.actions.insuredActionsReview.loadCoiDocuments({
                    rpName: this.rpName,
                    actionId: this.actionDetails.id,
                    // requirement for now is getting the first insure is sufficient thus first result's id is enough
                    verificationIds: [ this.actionDetails.evaluationResults[0].verificationId ],
                });
            }
        }

        private setCategoryNotFoundStatus(checked: boolean): void {
            this.categoryNotListed = checked;
            if (checked) {
                this.selectedOptions = [];
            }
        }

        private onCategoriesSelection(option: DropdownOption): void {
            this.selectedOptions = [ option ];
        }

        private onSave(): void {
            this.showConfirmationModal = true;
        }

        private onModalAction(confirmed: boolean): void {
            if (confirmed) {
                const data: InsuranceEndorsementActionResolveInput = {
                    $action: InsuranceInsuredActionType.endorsementFormNumberV1,
                    category: this.selectedOptions.length === 0
                        ? null
                        : this.selectedOptions[0].value,
                };
                this.$emit('resolve', data);
            }
            this.showConfirmationModal = false;
        }
    }
</script>
