<template>
    <div class="ComplianceWithCategoryResolutionReview">
        <div class="ComplianceWithCategoryResolutionReview__container">
            <div class="ComplianceWithCategoryResolutionReview__form">
                <Table class="ComplianceWithCategoryResolutionReview__table">
                    <template #head>
                        <HeadCell
                            data-test-id="ComplianceWithCategoryResolutionReview__fieldHeader"
                            class="ComplianceWithCategoryResolutionReview__fieldHeader"
                        >
                            Category
                        </HeadCell>
                        <HeadCell data-test-id="ComplianceWithCategoryResolutionReview__valueHeader">
                            Form Number
                        </HeadCell>
                        <HeadCell
                            data-test-id="ComplianceWithCategoryResolutionReview__decisionHeader"
                            class="ComplianceWithCategoryResolutionReview__decisionHeader"
                        >
                            Decision
                        </HeadCell>
                    </template>
                    <Row hoverable>
                        <Cell
                            data-test-id="ComplianceWithCategoryResolutionReview__fieldRowCell"
                            class="ComplianceWithCategoryResolutionReview__fieldRowCell"
                        >
                            {{ actionDetails.action.category }}
                        </Cell>
                        <Cell data-test-id="ComplianceWithCategoryResolutionReview__valueRowCell">
                            {{ actionDetails.action.providedValue }}
                        </Cell>
                        <Cell class="ComplianceWithCategoryResolutionReview__decisionHeader">
                            <div class="ComplianceWithCategoryResolutionReview__decisionsContainer">
                                <Button
                                    class="ComplianceWithCategoryResolutionReview__rejectButton"
                                    @click="resolve(false)"
                                >
                                    Reject
                                </Button>
                                <Button
                                    class="ComplianceWithCategoryResolutionReview__acceptButton"
                                    @click="resolve(true)"
                                >
                                    Accept
                                </Button>
                            </div>
                        </Cell>
                    </Row>
                </Table>
            </div>

            <div class="ComplianceWithCategoryResolutionReview__documents">
                <LogoLoader v-if="loading" />
                <DocumentsViewer v-if="!loading" :documents="docsStatus.list" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { CoiDocumentStatus } from '@/modules/insured-actions-review/vuex';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import DocumentsViewer from '@/modules/insured-actions-review/components/DocumentsViewer/DocumentsViewer.vue';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import {
        InsuranceComplianceWithCategoryResolutionActionDetails,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review/InsuranceInsuredActionDetails.model';
    import {
        InsuranceComplianceWithCategoryResolutionResolveInput,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review/InsuranceActionResolveInput.model';
    import { InsuranceInsuredActionType } from '@evidentid/insurance-facing-lib/models/insured-actions-review';

    export default Vue.extend({
        name: 'ComplianceWithCategoryResolutionReview',
        components: { Row, Cell, LogoLoader, Table, Button, DocumentsViewer, HeadCell },
        props: {
            actionDetails: {
                type: Object as PropType<InsuranceComplianceWithCategoryResolutionActionDetails>,
                required: true,
            },
        },
        computed: {
            rpName(): string {
                return this.$rp.current!;
            },
            docsStatus(): CoiDocumentStatus {
                if (
                    this.$store.state.insuredActionsReview.coiDocuments[this.rpName] &&
                    this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id]
                ) {
                    return this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id];
                } else {
                    return { status: OperationStatus.uninitialized, list: [] };
                }
            },
            loading(): boolean {
                return this.docsStatus.status === OperationStatus.loading;
            },
        },
        beforeMount() {
            if (
                this.docsStatus.status === OperationStatus.uninitialized &&
                this.actionDetails.evaluationResults.length > 0 &&
                this.actionDetails.evaluationResults[0].verificationId
            ) {
                this.$store.actions.insuredActionsReview.loadCoiDocuments({
                    rpName: this.rpName,
                    actionId: this.actionDetails.id,
                    // requirement for now is getting the first insure is sufficient thus first result's id is enough
                    verificationIds: [ this.actionDetails.evaluationResults[0].verificationId ],
                });
            }
        },
        methods: {
            resolve(accepted: boolean): void {
                const data: InsuranceComplianceWithCategoryResolutionResolveInput = {
                    $action: InsuranceInsuredActionType.complianceWithCategoryResolution,
                    accepted,
                };
                this.$emit('resolve', data);
            },
        },
    });
</script>
