import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import {
    AttributeDefinition,
    RelyingPartyRequestDetails,
    RelyingPartySearchRequest,
} from '@evidentid/rpweb-api-client/types';
import {
    CoiDetails,
    CompressedRelyingPartyRequestDetails,
    GetCertificatesOptions,
} from '@/modules/insured-certificates/types';
import { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';

const coiFilter = /\.certificate_of_insurance_document$/;
const removableCategoryPrefixes = [
    'Insurance - Self Attestation - ',
];

function sanitizeCategory(category: string): string {
    return removableCategoryPrefixes.reduce((result, prefix) => (
        result.startsWith(prefix)
            ? result.substr(prefix.length).trim()
            : result
    ), category);
}

function compressRprDetails(rpr: RelyingPartyRequestDetails): CompressedRelyingPartyRequestDetails {
    return {
        id: rpr.id,
        email: rpr.idOwner?.email || null,
        status: rpr.status,
        summary: rpr.summary,
        notes: rpr.notes,
        createdAt: rpr.createdAt,
        completedAt: rpr.completedAt,
    };
}

// eslint-disable-next-line max-params
async function getRprCertificates(
    api: RpWebApiClient,
    attributeTypes: AttributeDefinition[],
    rpName: string,
    rprId: string,
    useSpecificDetails?: boolean,
    coiSubmissionDate?: string,
) {
    // Extract RPR information
    // TODO(PRODUCT-19317): backward compatibility before deprecating using api.getRelyingPartyRequestDetails
    const rpr = useSpecificDetails
        ? await api.getRelyingPartyRequestSpecificDetails(rpName, rprId, { includeList: '\\S*.certificate_of_insurance_document' })
        : await api.getRelyingPartyRequestDetails(rpName, rprId);
    const rprCompressed = compressRprDetails(rpr);

    // Set-up bucket for found certificates
    const certificates: CoiDetails[] = [];

    // Group COI attributes per document
    const coiDocumentAttributeTypes = rpr.attributes.filter((x) => coiFilter.test(x.type)).map((x) => x.type);
    const coiNamespaces = coiDocumentAttributeTypes.map((x) => x.replace(coiFilter, ''));

    // Analyze all certificates
    for (const coiNamespace of coiNamespaces) {
        const coiAsas = rpr.attributeSharingAgreements?.filter((x) => x.attributeType.startsWith(`${coiNamespace}.`)) || [];
        const coiAttributes = rpr.attributes.filter((x) => x.type.startsWith(`${coiNamespace}.`));
        const base = attributeTypes.find((x) => x.attributePath === coiAttributes[0].type);
        const certificate = {
            id: coiNamespace,
            category: base?.category ? sanitizeCategory(base.category) : null,
            group: base?.group || null,
            uploadedAt: coiSubmissionDate || new Date().toISOString(),
            expiresAt: null as (string | null),
            request: { ...rprCompressed },
            content: coiAttributes.reduce((acc, attribute) => {
                const id = coiAsas.find((x) => x.attributeType === attribute.type)?.id ||
                    `${rprId}-${attribute.type}`;
                const name = attribute.type.substr(coiNamespace.length + 1);
                const attrDefinition = attributeTypes.find((x) => x.attributePath === attribute.type);
                return {
                    ...acc,
                    [name]: {
                        id,
                        name: attribute.displayName || (attrDefinition ? attrDefinition.readableAttributeName : name),
                        type: attribute.type,
                        status: attribute.status,
                        value: useSpecificDetails ? (attribute as any).values?.[0] : attribute.value,
                    },
                };
            }, {} as CoiDetails['content']),
        };

        // TODO(PRODUCT-15289): until a proper fix on wrong upload date, using passed in prop for submission history
        if (!coiSubmissionDate) {
            const coiDocumentAsa = coiAsas.find((x) => coiFilter.test(x.attributeType))!;
            certificate.uploadedAt = new Date(coiDocumentAsa.iat * 1000).toISOString();
        }

        if (certificate.content.expiration_date?.value) {
            const date = certificate.content.expiration_date.value;
            const year = date.year.toString().padStart(4, '0');
            const month = date.month.toString().padStart(2, '0');
            const day = date.day.toString().padStart(2, '0');
            certificate.expiresAt = `${year}-${month}-${day}`;
        }

        certificates.push(certificate);
    }

    return certificates;
}

async function getCoiRequestsByRpNameAndEmail(
    api: RpWebApiClient, rpName: string, email: string,
): Promise<RelyingPartySearchRequest[]> {
    // Retrieve RP requests
    const { requests } = await api.getRelyingPartyRequests(rpName, { textSearchPattern: email });

    // Filter requests to get only the expected
    const filteredRequests = requests
        .filter((x) => x.status === RelyingPartyRequestStatusType.complete)
        .filter((x) => x.attributeSharingAgreements.find((a) => coiFilter.test(a.attributeType)))
        .filter((x) => x.recipientEmail.toLowerCase() === email.toLowerCase());

    // Remove duplicates (optimization to load less RPRs)
    return filteredRequests
        .map((rpr) => [
            rpr,
            rpr.attributeSharingAgreements
                .map((x) => x.attributeType)
                .filter((x) => coiFilter.test(x)),
        ] as [ RelyingPartySearchRequest, string[] ])
        .filter(([ rpr, types ], index, arr) => {
            const prevTypes = arr.slice(0, index).reduce((acc, [ , x ]) => [ ...acc, ...x ], [] as string[]);
            return !types.every((x) => prevTypes.includes(x));
        })
        .map(([ rpr ]) => rpr);
}

// eslint-disable-next-line max-params
export async function getInsuranceDetails(
    api: RpWebApiClient,
    rpName: string,
    email: string,
    options?: GetCertificatesOptions,
    rprId?: string,
    coiSubmissionDate?: string,
): Promise<CoiDetails[]> {
    // Format options
    const offset = options?.offset || 0;
    const limit = options?.limit || Infinity;

    // Retrieve attribute types definition
    const { attributeTypes } = await api.getAttributeTypes(rpName);

    let computedCertificates = [] as any[];
    if (rprId) {
        computedCertificates =
            await getRprCertificates(api, attributeTypes, rpName, rprId, true, coiSubmissionDate);
    } else {
        const requests = await getCoiRequestsByRpNameAndEmail(api, rpName, email)
        // Retrieve details for each of these requests
        computedCertificates = await Promise.all(
            requests
                .slice(offset, offset + limit)
                .map((x) => getRprCertificates(api, attributeTypes, rpName, x.id)),
        );
    }

    // Combine all certificates,
    // and redundant certificates (request with multiple COIs, where part of them is old)
    return uniqBy(flatten(computedCertificates), (x) => x.id);
}
