import {
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionMatchType,
    InsuranceInsuredActionType,
    InsuranceInsuredEntityNameMatchingAction,
} from '@evidentid/insurance-facing-lib/models/insured-actions-review';
import { InsuredActionScopeType } from '@/modules/insured-actions-review/types';

type FilteredInsuranceInsuredActionType =
    Exclude<InsuranceInsuredActionType, InsuranceInsuredActionType.entityNameMatchingV1>;
const actionScopeMap: Record<FilteredInsuranceInsuredActionType, InsuredActionScopeType> = {
    [InsuranceInsuredActionType.endorsementFormNumberV1]: InsuredActionScopeType.global,
    [InsuranceInsuredActionType.evaluatedEntityMappingV1]: InsuredActionScopeType.insured,
    [InsuranceInsuredActionType.nonExtractionCriterionResolutionV1]: InsuredActionScopeType.insured,
    [InsuranceInsuredActionType.complianceWithCategoryResolution]: InsuredActionScopeType.global,
};

export function getActionScopeType(insuredAction: InsuranceInsuredActionDetails): InsuredActionScopeType {
    const entityMatchingAction = insuredAction.action as InsuranceInsuredEntityNameMatchingAction;

    if (insuredAction.action.$action === InsuranceInsuredActionType.entityNameMatchingV1) {
        return entityMatchingAction.matchType === InsuranceInsuredActionMatchType.insured
            ? InsuredActionScopeType.insured
            : InsuredActionScopeType.global;
    }

    return actionScopeMap[entityMatchingAction.$action as FilteredInsuranceInsuredActionType];
}
