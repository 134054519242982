import { InsuranceComplianceStatus, InsuranceEvaluationResult } from '@evidentid/insurance-facing-lib/models/insured-details';
import isNil from 'lodash/isNil';

export function isEmptyValue(value: any): boolean {
    return isNil(value) ||
        (
            typeof value === 'object' &&
            Object.values(value).every((x) => isNil(x))
        );
}

export function isNonCompliantField(fieldName: string, evaluationResults: InsuranceEvaluationResult[]): boolean {
    return evaluationResults.some(
        (result) =>
            result.usedFields?.some((usedField) => usedField.split('/').pop() === fieldName) &&
            result.result === InsuranceComplianceStatus.nonCompliant,
    );
}

export function shouldBeOmitted(key: string, value: any, evaluationResults: InsuranceEvaluationResult[]): boolean {
    const notEvaluatedEmpty = !isNonCompliantField(key, evaluationResults) && isEmptyValue(value);
    return key === 'coverageStatus' || notEvaluatedEmpty;
}

