import { DeclineReason } from '@evidentid/rpweb-api-client/types';

export const DECLINE_REASON_TEXTS: Record<DeclineReason, { header: string, body: string }> = {
    [DeclineReason.Declined]: {
        header: 'No Coverage Provided - Declined',
        body: 'Insured declined to include coverage in their upload. Please contact insured if this is incorrect.',
    },
    [DeclineReason.Missing]: {
        header: 'No Coverage Provided - Doesn\'t Have Coverage',
        body: 'Insured reported they do not have this insurance. Please contact insured for more details.',
    },
    [DeclineReason.ExceptionRequested]: {
        header: 'No Coverage Provided - Exception Requested',
        body: 'Insured requested an exception for this coverage type.',
    },
    [DeclineReason.NotRenewed]: {
        header: 'No Coverage Provided - Not Renewed',
        body: 'Insured has reported they will not renew this policy. Please contact insured for more details if needed.',
    },
};
