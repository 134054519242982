<template>
    <Row
        hoverable
        class="CoverageGroupCriteriaTableRow"
        :class="{ 'CoverageGroupCriteriaTableRow--deprecated': template.deprecated }"
    >
        <Cell class="CoverageGroupCriteriaTableRow__criteriaName">
            <div>
                {{ displayMetadata.title }}
                <div
                    v-if="hasTooltip"
                    v-tooltip="criterionTooltipData"
                    class="CoverageGroupCriteriaTableRow__hintIcon"
                >
                    <EidIcon
                        v-if="template.deprecated"
                        class="CoverageGroupCriteriaTableRow__hintIcon--svg"
                        alt="icon"
                        :icon-src="exclamationCircle"
                        :svg-props="{color: alertWarningColor}"
                    />
                    <EidIcon
                        v-else-if="isNonInputCriterionAndInvalid"
                        class="CoverageGroupCriteriaTableRow__hintIcon--svg"
                        alt="icon"
                        :icon-src="exclamationCircle"
                        :svg-props="{color: alertRedColor}"
                    />
                    <FontAwesomeIcon v-else :icon="faQuestionCircle" />
                </div>
            </div>
        </Cell>
        <Cell class="CoverageGroupCriteriaTableRow__verify">
            <SwitchToggle
                :value="criterion.verify"
                :disabled="shouldDisableVerify"
                hide-label
                @input="updateVerification"
            />
        </Cell>
        <Cell class="CoverageGroupCriteriaTableRow__condition">
            {{ condition }}
        </Cell>
        <Cell class="CoverageGroupCriteriaTableRow__requiredValue">
            <div v-if="!criterion.verify" class="CoverageGroupCriteriaTableRow__notVerifyText">
                Will be collected but not verified
            </div>
            <div v-else-if="staticText" class="CoverageGroupCriteriaTableRow__staticText">
                {{ staticText }}
            </div>
            <template v-else-if="form">
                <div
                    v-if="isVisibleValueDependentFieldPlaceholder"
                    class="CoverageGroupCriteriaTableRow__valueDependentFieldPlaceholder"
                >
                    {{ criterionDependentPlaceholderText }}
                </div>
                <JsonSchemaFormElement
                    v-else
                    :form="form"
                    :value="displayReference.value"
                    :show-error="showError || accessed"
                    :custom-component-input="getCustomComponent"
                    hide-title
                    @input="onCriterionChange"
                    @blur="onCriterionBlur"
                />
            </template>
        </Cell>
        <Cell
            v-if="someInsuredFieldSubstitution"
            class="CoverageGroupCriteriaTableRow__substituteInsuredField"
        >
            <Checkbox
                v-if="isVisibleSubstituteInsuredField"
                :value="substitutionFlagValue"
                @input="onSubstitutionFlagChange"
            />
        </Cell>

        <Cell class="CoverageGroupCriteriaTableRow__actions">
            <div class="CoverageGroupCriteriaTableRow__actionsContainer">
                <EidIcon
                    v-if="deletable"
                    class="CoverageGroupCriteriaTableRow__deleteIcon"
                    alt="delete"
                    :icon-src="deleteIcon"
                    :svg-props="{color: evidentGreenColor}"
                    @click="deleteRow"
                />
                <div
                    class="CoverageGroupCriteriaTableRow__detailsIcon"
                    :class="{'CoverageGroupCriteriaTableRow__detailsIcon--disabled': !criterion.verify}"
                    @click="openSettings"
                >
                    <FontAwesomeIcon :icon="faChevronRight" />
                </div>
            </div>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import {
        InsuranceCoverageCriterion,
        InsuranceCoverageCriterionField,
        InsuranceCoverageCriterionInput,
        InsuranceCoverageCriterionTemplate,
        InsuranceCriteriaDisplayMeta,
        InsuranceSchema,
        InsuranceInsuredField,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import tranIcon from '@/assets/icons/bin-icon.svg';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { SwitchToggle } from '@evidentid/dashboard-commons/components/SwitchToggle';
    import { FormInput } from '@evidentid/dashboard-commons/components/Form';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { addEnumLabelToSchemaIfAvailable } from '@/modules/decisioning-criteria/utils/coverageCriteria';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import createForm from '@evidentid/json-schema/createForm';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import exclamationCircle from '@/modules/insured-details/assets/exclamation-circle.svg';
    import {
        coverageTypeFieldReferenceDelimiter,
        GeneralAggregateLimitReferences,
    } from '@/modules/decisioning-criteria/types';
    import {
        IsReferenceStartsWithIsInvalid,
    } from '@/modules/decisioning-criteria/components/CreateEditCoverageCriteriaGroup/types';
    import { CriteriaCanInsuredFieldSubstitution }
        from '@/modules/decisioning-criteria/components/CoverageGroupCriteriaTable/types';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import {
        getCriterionJsonSchemaComponent,
    } from '@/modules/decisioning-criteria/utils/getCriterionJsonSchemaComponent';
    import { JsonSchemaFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import {
        NON_EXTRACTION_FIELD_PREFIX,
    } from '@/modules/decisioning-criteria/components/CoverageCriterionSettings/constants';
    import { getFlagDisabledReferenceValueSchema } from '@/modules/decisioning-criteria/utils/getFlagDisabledReferenceValueSchema';

    enum CriterionField {
        generalAggregateLimit = 'coverage.generalAggregateLimit',
    }

    @Component({
        components: {
            Cell,
            Checkbox,
            EidIcon,
            FontAwesomeIcon,
            FormInput,
            Row,
            SwitchToggle,
            JsonSchemaFormElement,
        },
    })
    export default class CoverageGroupCriteriaTableRow extends Vue {
        @Prop({ type: Object, required: true })
        private criterion!: InsuranceCoverageCriterionInput | InsuranceCoverageCriterion;

        @Prop({ type: Object, required: true })
        private template!: InsuranceCoverageCriterionTemplate;

        @Prop({ type: Boolean, default: false })
        private deletable!: boolean;

        @Prop({ type: Boolean, default: false })
        private showError!: boolean;

        @Prop({ type: Boolean as PropType<boolean>, default: false })
        private someInsuredFieldSubstitution!: boolean;

        @Prop({ type: Object as PropType<CriteriaCanInsuredFieldSubstitution>, default: () => ({}) })
        private criteriaCanInsuredFieldSubstitution!: CriteriaCanInsuredFieldSubstitution;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Object, default: () => ({}) })
        private accessedReferenceMap!: Record<string, boolean>;

        @Prop({ type: Function as PropType<IsReferenceStartsWithIsInvalid>, required: true })
        private isReferenceStartsWithIsInvalid!: IsReferenceStartsWithIsInvalid;

        private exclamationCircle = exclamationCircle;
        private faChevronRight = faChevronRight;
        private deleteIcon = tranIcon;
        private faQuestionCircle = faQuestionCircle;
        private getCustomComponent = getCriterionJsonSchemaComponent();
        private evidentGreenColor = tailwindColors.eidTrustGreen.DEFAULT;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;
        private alertWarningColor = tailwindColors.eidWarningYellow.DEFAULT;

        @Watch('displayReferenceSchema', { immediate: true })
        private schemaChange() {
            if (this.displayReferenceSchema && this.displayReferenceKey && this.form) {
                this.$emit('validityChange', {
                    isValid: this.isRowValid,
                    reference: this.getCombinedReference(this.displayReferenceKey),
                });
            }
        }

        @Watch('template', { immediate: true })
        private templateChange() {
            if (this.criterion.verify) {
                this.validateAllReferences();
            }
        }

        private get form(): JsonFormBase<JsonSchema> | null {
            if (!this.displayReferenceSchema) {
                return null;
            }

            return createForm(standardizeCriterionSchema(
                this.displayReferenceSchema,
                this.insuredFields,
                this.flagDisabledReferenceValueSchema,
            ));
        }

        private get flagDisabledReferenceValueSchema(): InsuranceSchema | null {
            return getFlagDisabledReferenceValueSchema({ template: this.template, criterion: this.criterion });
        }

        private get displayMetadata(): InsuranceCriteriaDisplayMeta {
            return this.template.displayMetadata;
        }

        private get displayReferenceKey(): string | null {
            const conditionalRefs = this.displayMetadata.conditionalValueReference;
            if (conditionalRefs) {
                const flagRef = conditionalRefs.flagValueReference;
                const flagValue = this.criterion.evaluator.references[flagRef]?.value;
                return flagValue ? conditionalRefs.flagEnabledReference : conditionalRefs.flagDisabledReference;
            } else {
                return this.displayMetadata.requiredValueReference || null;
            }
        }

        private get displayReference(): InsuranceCoverageCriterionField | null {
            if (this.displayMetadata.conditionalValueReference && this.displayReferenceKey) {
                return this.criterion.evaluator.references[this.displayReferenceKey];
            } else {
                return this.displayReferenceKey ? this.criterion.evaluator.references[this.displayReferenceKey] : null;
            }
        }

        private get displayReferenceSchema(): JsonSchema | null {
            if (this.displayReference) {
                const enumLabelsMap = this.displayMetadata
                    .enumValues?.find((x) => x.reference === this.displayMetadata.requiredValueReference)?.values;
                return enumLabelsMap
                    ? addEnumLabelToSchemaIfAvailable(this.displayReference.schema, enumLabelsMap)
                    : this.displayReference.schema;
            } else {
                return null;
            }
        }

        private get staticText(): string | null {
            return this.displayMetadata.nonInputCriterionText || null;
        }

        private get condition(): string {
            return this.criterion.verify && this.displayMetadata.condition ? this.displayMetadata.condition : '-';
        }

        private get substitutionFlagValue(): boolean {
            const key = this.displayMetadata.conditionalValueReference?.flagValueReference;
            return key
                ? this.criterion.evaluator.references[key]?.value
                : false;
        }

        private get accessed(): boolean {
            return this.displayReferenceKey
                ? this.accessedReferenceMap[this.getCombinedReference(this.displayReferenceKey)] || false
                : false;
        }

        private get isRowValid() {
            // TODO(PRODUCT-16737): remove once enum type/value mismatch fixed
            if (this.displayReference && 'enum' in this.displayReference?.schema) {
                return true;
            }

            if (!this.criterion.verify) {
                return true;
            }

            const trimStringValue = (x: any) => (typeof x === 'string' ? x.trim() : x);
            const trimmedValue = Array.isArray(this.displayReference?.value)
                ? this.displayReference?.value.map(trimStringValue)
                : trimStringValue(this.displayReference?.value);
            return this.form?.isValid(trimmedValue, true) || false;
        }

        private get tooltipDescription(): string {
            return this.displayReference?.schema.description || '';
        }

        private get tooltipDeprecated(): string {
            const messageStart = `<span class="VTooltip__text VTooltip__text--gold">Deprecated`;

            if (this.tooltipDescription) {
                return `${messageStart} - </span>${this.tooltipDescription}`;
            } else {
                return `${messageStart}</span>`;
            }
        }

        private get tooltipNonInputCriterionAndInvalid(): string {
            const messageStart = `<span class="VTooltip__text VTooltip__text--red">Invalid`;

            if (this.tooltipDescription) {
                return `${messageStart} - </span>${this.tooltipDescription}`;
            } else {
                return `${messageStart}</span>`;
            }
        }

        private get isVisibleValueDependentFieldPlaceholder(): boolean {
            return this.isCriterionInputValueDependent &&
                this.isCriterionInputDependentHasValue &&
                Boolean(this.criterionDependentPlaceholderText);
        }

        private get isNonInputCriterionAndInvalid(): boolean {
            const hasFieldsInAdvancedSettings = Object.keys(this.template.evaluator.references).length > 0;
            const isNonInputCriteria = Boolean(this.staticText) && hasFieldsInAdvancedSettings;

            if (!isNonInputCriteria && !this.isVisibleValueDependentFieldPlaceholder) {
                return false;
            }

            const nonInputCriterionReferenceStart = this.getCombinedReference();
            return this.isReferenceStartsWithIsInvalid(nonInputCriterionReferenceStart);
        }

        private get criterionTooltipData(): { content: string, html?: boolean, classes?: string } {
            if (this.template.deprecated || this.isNonInputCriterionAndInvalid) {
                return {
                    content: this.template.deprecated ?
                        this.tooltipDeprecated
                        : this.tooltipNonInputCriterionAndInvalid,
                    html: true,
                    classes: 'VTooltip--dark VTooltip--max-w-sm',
                };
            }

            return {
                content: this.tooltipDescription,
            };
        }

        private get hasTooltip(): boolean {
            return this.template.deprecated || this.isNonInputCriterionAndInvalid || Boolean(this.tooltipDescription);
        }

        private get isCommercialGlAggregateLimit(): boolean {
            return this.criterion.coverageType === InsuranceCoverageType.commercialGeneralLiability &&
                this.criterion.field === CriterionField.generalAggregateLimit;
        }

        private get isCriterionInputValueDependent(): boolean {
            return this.isCommercialGlAggregateLimit;
        }

        private get isCriterionInputDependentHasValue(): boolean {
            if (this.isCommercialGlAggregateLimit) {
                const value = this.criterion.evaluator.references[GeneralAggregateLimitReferences.conditionSets].value;
                return Boolean(value && value.length > 0);
            }
            return false;
        }

        private get criterionDependentPlaceholderText(): string | null {
            if (this.isCommercialGlAggregateLimit) {
                return 'Condition set(s) applied. Configure required value in advanced settings.';
            }
            return null;
        }

        private get isVisibleSubstituteInsuredField(): boolean {
            return this.criteriaCanInsuredFieldSubstitution[this.criterion.field as string] &&
                (!this.isCriterionInputValueDependent || !this.isCriterionInputDependentHasValue);
        }

        private get shouldDisableVerify(): boolean {
            return this.template.displayMetadata.verifyToggleDisabled ||
                this.criterion.field.startsWith(NON_EXTRACTION_FIELD_PREFIX);
        }

        private onSubstitutionFlagChange(value: boolean): void {
            const key = this.displayMetadata.conditionalValueReference?.flagValueReference;
            if (this.displayMetadata.conditionalValueReference && key) {
                this.updateCriterion(key, value);
                // remove invalid status of hidden field to prevent save being disabled while visible form are valid
                const hiddenReferenceKey = value
                    ? this.displayMetadata.conditionalValueReference.flagDisabledReference
                    : this.displayMetadata.conditionalValueReference.flagEnabledReference;
                this.$emit('validityChange', {
                    reference: [ this.criterion.coverageType, this.criterion.field, hiddenReferenceKey ]
                        .join(coverageTypeFieldReferenceDelimiter),
                    isValid: true,
                });
            }
        }

        private onCriterionChange(value: any): void {
            if (this.displayReferenceKey) {
                const formValue = this.form ? this.form.getValue(value) : value;
                this.updateCriterion(this.displayReferenceKey, formValue);
            }
        }

        private onCriterionBlur(): void {
            if (this.displayReferenceKey) {
                this.$emit('reference-accessed', {
                    reference: this.getCombinedReference(this.displayReferenceKey),
                    isValid: this.isRowValid,
                });
            }
        }

        private updateCriterion(referenceKey: string, value: any): void {
            const criterion = {
                ...this.criterion,
                evaluator: {
                    ...this.criterion.evaluator,
                    references: {
                        ...this.criterion.evaluator.references,
                        [referenceKey]: { ...this.criterion.evaluator.references[referenceKey], value },
                    },
                },
            };
            this.$emit('input', criterion);
            if (referenceKey !== this.displayMetadata.conditionalValueReference?.flagValueReference) {
                this.$emit('reference-accessed', {
                    reference: this.getCombinedReference(referenceKey),
                    isValid: this.isRowValid,
                });
            }
        }

        private updateVerification(value: boolean): void {
            this.$emit('input', { ...this.criterion, verify: value });
            if (value) {
                this.validateAllReferences();
            } else {
                this.$emit('validityChange', {
                    reference: this.getCombinedReference(),
                    isValid: true,
                });
            }
        }

        private openSettings(): void {
            if (this.criterion.verify) {
                this.$emit('openSettings', this.criterion);
            }
        }

        private deleteRow(): void {
            this.$emit('delete');
            this.$emit('validityChange', {
                isValid: true,
                reference: this.getCombinedReference(),
            });
        }

        private getCombinedReference(reference?: string): string {
            // no reference will emit only coverage type and field, will remove all criterion invalid references
            return [ this.criterion.coverageType, this.criterion.field, reference ]
                .filter(Boolean)
                .join(coverageTypeFieldReferenceDelimiter);
        }

        private validateAllReferences(): void {
            const hiddenReference = this.substitutionFlagValue
                ? this.displayMetadata.conditionalValueReference?.flagDisabledReference
                : this.displayMetadata.conditionalValueReference?.flagEnabledReference;
            Object.entries(this.criterion.evaluator.references).forEach(([ name, reference ]) => {
                if (name !== hiddenReference) {
                    const form = createForm(standardizeCriterionSchema(
                        reference.schema,
                        this.insuredFields,
                        this.flagDisabledReferenceValueSchema,
                    ));
                    const value = form.getValue(reference.value, true);
                    this.$emit('validityChange', {
                        isValid: form.isValid(value, true),
                        reference: [ this.criterion.coverageType, this.criterion.field, name ]
                            .join(coverageTypeFieldReferenceDelimiter),
                    });
                }
            });
        }
    }
</script>
