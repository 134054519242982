<template>
    <Modal
        open
        :additional-class-name="additionalClasses"
        @close="close"
    >
        <template #header>
            Submission History for {{ insured.displayName }}
        </template>
        <LogoLoader v-if="loading" class="h-full w-full flex items-center justify-center" />
        <div v-else class="InsuranceInformationalModal__bodyText">
            <CoiSubmissionHistoryTable :submissions="historicCoiSubmissions" @show-coi="showCoi" />
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Vue } from 'vue-property-decorator';
    import { PropType } from 'vue';
    import orderBy from 'lodash/orderBy';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import {
        InsuranceCoverageModel,
        InsuranceInsured,
        InsuranceInsuredCoverageDetails,
        InsuranceVerificationRequest,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import CoiSubmissionHistoryTable
        from '@/modules/insured-details/components/CoiSubmissionHIstroyTable/CoiSubmissionHistoryTable.vue';
    import { HistoricCoiSubmission } from '@/models/HistoricCoiSubmission.model';
    import difference from 'lodash/difference';

    export default Vue.extend({
        name: 'ViewHistoricCoiProcedure',
        components: {
            CoiSubmissionHistoryTable,
            LogoLoader,
            Modal,
        },
        props: {
            insured: {
                type: Object as PropType<InsuranceInsured>,
                required: true,
            },
            coverageModels: {
                type: Array as PropType<InsuranceCoverageModel[]>,
                required: true,
            },
        },
        data() {
            return {
                open: true,
                showingCoi: false,
                onInsuredDetailsView: false,
            };
        },
        computed: {
            rpName(): string {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$rp.current!;
            },
            store(): any {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$store;
            },
            loading(): boolean {
                return this.loadingVerifications ||
                    this.loadingCoverageDetails;
            },
            loadingVerifications(): boolean {
                return this.verificationsStatus.status === OperationStatus.loading;
            },
            loadingCoverageDetails(): boolean {
                return this.coverageDetailsStatus.status === OperationStatus.loading;
            },
            verificationsStatus(): { status: OperationStatus, list: InsuranceVerificationRequest[] } {
                return this.store.state.insuredDetails.verificationRequestsStatus[this.rpName] || {
                    status: OperationStatus.uninitialized,
                    list: [],
                };
            },
            coverageDetailsStatus(): { status: OperationStatus, list: InsuranceInsuredCoverageDetails[] } {
                return this.store.state.insuredDetails.coverageDetailsListStatus[this.rpName] || {
                    status: OperationStatus.uninitialized,
                    list: [],
                };
            },
            historicCoiSubmissions(): HistoricCoiSubmission[] {
                const submissions = [] as HistoricCoiSubmission[];
                this.verificationsStatus.list.forEach((verification) => {
                    const pendingSubmission = !verification.inputReceivedAt && !verification.timeoutAt;
                    if (!pendingSubmission) {
                        const notProvidedCoverageTypes = this.getNotProvidedCoverageTypes(verification);
                        const currentMap: Record<string, InsuranceCoverageType[]> =
                            this.coverageDetailsStatus.list.reduce((accu, details) => {
                                // coverage details' request id IS verification id
                                const verificationId = details.coverage?.requestId;
                                const types = verificationId && accu[verificationId] || [];
                                return {
                                    ...accu,
                                    ...(verificationId && { [verificationId]: [ ...types, details.coverageType ] }),
                                };
                            }, {} as Record<string, InsuranceCoverageType[]>);
                        verification.reasons.forEach(
                            (reason) => {
                                const isCurrent =
                                    currentMap[verification.verificationId]?.includes(reason.coverageType) || false;
                                submissions.push(this.constructSubmission(
                                    verification, reason.coverageType, notProvidedCoverageTypes, isCurrent,
                                ));
                            },
                        );
                    }
                });
                return orderBy(submissions, [ 'requestDate', 'coverageType' ], [ 'desc', 'asc' ]);
            },
            additionalClasses(): string {
                const hide = this.showingCoi ? 'invisible' : '';
                return `ViewHistoricCoiModal ${hide}`;
            },
        },
        mounted(): void {
            this.onInsuredDetailsView = Boolean(this.$route.name === 'dashboard' && this.$route.query.insured);
            this.store.actions.insuredDetails.loadVerificationRequests({
                rpName: this.rpName,
                insuredId: this.insured.id,
            });
            if (!this.onInsuredDetailsView) {
                this.store.actions.insuredDetails.loadCoverageDetailsList({
                    rpName: this.rpName,
                    insuredId: this.insured.id,
                });
            }
        },
        destroyed(): void {
            this.store.actions.insuredDetails.clearVerificationRequests({ rpName: this.rpName });
            if (!this.onInsuredDetailsView) {
                this.store.actions.insuredDetails.clearCoverageDetailsList({ rpName: this.rpName });
            }
        },
        methods: {
            close(): void {
                this.$emit('abort');
                this.open = false;
            },
            showCoi(submission: HistoricCoiSubmission): void {
                this.showingCoi = true;
                // TODO(PRODUCT-18675): remove "as any" after the migration to the newer version. apply to whole file
                (this as any).$procedures.execute('showInsuredCoverageCois', {
                    rpName: this.rpName,
                    email: this.insured?.contactEmail || '',
                    coverageType: submission.coverageType,
                    coverageTypeLabel: submission.coverageTypeLabel,
                    rprId: submission.rprId,
                    coiSubmissionDate: submission.submissionDate,
                }, this.onCoiClosed);
            },
            onCoiClosed(): void {
                this.showingCoi = false;
            },
            getNotProvidedCoverageTypes(verification: InsuranceVerificationRequest): InsuranceCoverageType[] {
                const requestedCoverageTypes = difference(
                    verification.reasons.map((x) => x.coverageType),
                    verification.cancelledCoverageTypes,
                );
                return difference(
                    requestedCoverageTypes,
                    verification.extractedCoverageTypes,
                );
            },
            constructSubmission(
                verification: InsuranceVerificationRequest,
                coverageType: InsuranceCoverageType,
                notProvidedCoverageTypes: InsuranceCoverageType[],
                isCurrentSubmission: boolean,
            ): HistoricCoiSubmission {
                return {
                    requestDate: verification.createdAt,
                    submissionDate: verification.inputReceivedAt,
                    coverageType,
                    coverageTypeLabel:
                        this.coverageModels.find((x) => x.coverageType === coverageType)?.label || coverageType,
                    rprId: verification.requestId,
                    verificationId: verification.verificationId,
                    insuredId: this.insured.id,
                    isNoResponse: Boolean(verification.timeoutAt) || !verification.inputReceivedAt,
                    isNotProvided: notProvidedCoverageTypes.includes(coverageType),
                    isCurrentSubmission,
                    declineReason: verification.declinedCoverageTypes?.[coverageType],
                };
            },
        },
    });
</script>
