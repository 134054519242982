<template>
    <div class="PolicyInfo">
        <div class="PolicyInfo__bodyBasicInfo">
            <span class="PolicyInfo__bodySectionTitle">INSURED</span>
            <PolicyRecord
                title="Name"
                :value="coverage.policy.insured"
                :evaluation-error="getEvaluationResult('policy/insured')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Status"
                :value="coverageStatus ? 'Active' : 'Inactive'"
                :evaluation-error="getEvaluationResult('details/coverageStatus')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
            <PolicyRecord
                title="Currency"
                :value="currencyLabel"
                :evaluation-error="getEvaluationResult('policy/currency')"
                @goToCriterion="goToCriterion"
                @grantExceptionToCriterion="grantExceptionToCriterion"
                @removeExceptions="removeExceptions"
            />
        </div>
        <div
            v-if="producerDetails.length > 0"
            data-test-id="PolicyInfo__producerSection"
            class="PolicyInfo__bodyBasicInfo grid grid-cols-5"
        >
            <span class="PolicyInfo__bodySectionTitle col-span-1">PRODUCER</span>
            <div class="grid grid-cols-4 gap-y-4 col-span-4">
                <PolicyRecord
                    v-for="producerDetail in producerDetails"
                    :key="producerDetail.title"
                    :class="{ 'col-span-4': producerDetail.title === 'Producer Address' }"
                    :value="producerDetail.value"
                    :title="producerDetail.title"
                />
            </div>
        </div>
        <PolicyInfoCarrierInfo
            v-if="isShowingCarrierInfo"
            class="PolicyInfo__bodyBasicInfo"
            :carrier="coverage.policy.carrier"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoOtherCellsInfo
            :evaluation-errors="evaluationErrors"
            :coverage="coverage"
            :limits="limits"
            :evaluation-results="evaluationResults"
            :get-evaluation-result="getEvaluationResult"
            @go-to-criterion="goToCriterion"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${coverage.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleCoverageFields"
            :schema="singleCoverageFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            :id="`#/${coverage.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__singleCoverageEndorsementsFields"
            :schema="singleCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfMultiPropsCoverageFields > 0"
            :id="`#/${coverage.coverageType}/details`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiPropsCoverageFields"
            :schema="multiPropsCoverageFields"
            :value="values"
            :evaluation-errors="evaluationErrors"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <JsonSchemaView
            v-if="numberOfEndorsementsMultiCoverageFields > 0"
            :id="`#/${coverage.coverageType}/details/endorsements`"
            class="PolicyInfo__jsonSchemaView PolicyInfo__multiCoverageEndorsementsFields"
            :schema="multiCoverageEndorsementsFields"
            :value="endorsementsFieldsValues"
            :evaluation-errors="evaluationErrors"
            :start-index="numberOfMultiPropsCoverageFields + 1"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <div
            v-if="crossCoverageCriteria.length > 0 || coverage.endorsements.length > 0"
            class="PolicyInfo__multiCoverageInfoHeader"
        >
            <span class="PolicyInfo__bodySectionTitle">COVERAGE CRITERIA SPANNING MULTIPLE FIELDS</span>
            <div
                v-tooltip="multiCoverageTooltipDescription"
                class="PolicyInfo__hintIcon"
            >
                <FontAwesomeIcon :icon="faQuestionCircle" />
            </div>
        </div>
        <EndorsementsView
            :endorsements="coverage.endorsements"
            :evaluation-error="getEvaluationResult('details/endorsements')"
            @goToCriterion="goToCriterion"
            @grantExceptionToCriterion="grantExceptionToCriterion"
            @removeExceptions="removeExceptions"
        />
        <MultiFieldCriterion
            v-for="(criterion, index) in crossCoverageCriteria"
            :key="criterion.name"
            :criterion="criterion"
            :index="index + coverage.endorsements.length + 1"
            v-on="$listeners"
        />
        <CollateralList
            v-if="collaterals.length > 0"
            :coverage-type="coverage.coverageType"
            :collaterals="collaterals"
            :collateral-entities="collateralEntities"
            :evaluation-errors="evaluationErrors"
            @grant-evaluate-entity-exception="grantEvaluateEntityException"
            @remove-exceptions="removeExceptions"
        />
        <PolicyInfoNonExtraction
            :get-evaluation-result="getEvaluationResult"
            :non-extraction-results="nonExtractionResults"
            @grant-exception-to-criterion="grantExceptionToCriterion"
            @remove-exceptions="removeExceptions"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import pickBy from 'lodash/pickBy';
    import isNil from 'lodash/isNil';
    import getIn from 'lodash/get';
    import uniqWith from 'lodash/uniqWith';
    import isEqual from 'lodash/isEqual';
    import {
        InsuranceInsuredCoverage,
        InsuranceInsuredCoverageDetails,
        NonExtractionResults,
    } from '@evidentid/rpweb-api-client/types';
    import {
        InsuranceExceptionInput,
        InsuranceEvaluationResult, InsuranceCoverageObjectType,
    } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { BrokerInfo } from '@/modules/dashboard/types';
    import PolicyRecord from '@/modules/insured-details/components/PolicyRecord/PolicyRecord.vue';
    import { JsonSchemaView } from '@/modules/insured-details/components/JsonSchemaView';
    import { getBasicSchemaByValue } from '@/modules/insured-details/utils/jsonSchemaUtilities';
    import EvaluationError from '@/modules/insured-details/components/EvaluationError/EvaluationError.vue';
    import omitBy from 'lodash/omitBy';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import EndorsementsView from '@/modules/insured-details/components/EndorsementsView/EndorsementsView.vue';
    import { InsuredMultiFieldCriterion } from '@/modules/insured-details/types';
    import MultiFieldCriterion from '@/modules/insured-details/components/MultiFieldCriterion/MultiFieldCriterion.vue';
    import { FIELD_NOT_AVAILABLE_MESSAGE } from '@/modules/insured-details/constant';
    import { getEnumDefaultDisplayValue } from '@/utils/getEnumDefaultDisplayValue';
    import CollateralList from '@/modules/insured-details/components/CollateralList/CollateralList.vue';
    import { CoverageFieldCollateral } from '@/modules/insured-details/models/CoverageFieldCollateral.model';
    import { currencyFormatter } from '@/modules/insured-details/utils/currencyFormatter';
    import { getCoverageAddressObjectString } from '@/modules/insured-details/utils/getCoverageAddressObjectString';
    import { PropType } from 'vue';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import PolicyInfoCarrierInfo from './PolicyInfoCarrierInfo/PolicyInfoCarrierInfo.vue';
    import PolicyInfoNonExtraction from './PolicyInfoNonExtraction/PolicyInfoNonExtraction.vue';
    import PolicyInfoOtherCellsInfo from './PolicyInfoOtherCellsInfo/PolicyInfoOtherCellsInfo.vue';
    import { isShowingCarrierInfo } from './PolicyInfoCarrierInfo/utils';
    import { typeOfInsuranceAttributes } from './PolicyInfoOtherCellsInfo/utils';
    import { isEmptyValue, shouldBeOmitted } from './utils';
    import {
        combineSpecialCoverageFieldValues,
    } from '@/modules/insured-details/utils/combineSpecialCoverageFieldValues/combineSpecialCoverageFieldValues';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { EnumCategories } from '@/modules/dashboard/models/EnumCategories.model';
    import {
        transformValuesWithIsPresent,
    } from '@/modules/insured-details/utils/transformValuesWithIsPresent/transformValuesWithIsPresent';

    function formatPhoneNumber(number: string): string {
        // Only US phone numbers are in use.
        return number.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    function getBrokerInfo(
        brokerInfo?: BrokerInfo | null,
    ): { title: string, value: string | null }[] {
        if (!brokerInfo) {
            return [];
        }

        return [
            {
                title: 'Broker Business Name',
                value: brokerInfo.name || null,
            },
            {
                title: 'Broker Phone Number',
                value: brokerInfo.phoneNumber ?
                    formatPhoneNumber(brokerInfo.phoneNumber)
                    : null,
            },
            {
                title: 'Broker Contact',
                value: brokerInfo.contactName || null,
            },
        ];
    }

    function getProducerInfo(
        coverage: InsuranceInsuredCoverage,
    ): { title: string, value: string | null }[] {
        return [
            {
                title: 'Producer Business Name',
                value: coverage.details.brokerName || coverage.details.producerName || null,
            },
            {
                title: 'Producer Contact Name',
                value: coverage.details.producerContactName || null,
            },
            {
                title: 'Producer Email',
                value: coverage.details.producerEmail || null,
            },
            {
                title: 'Producer Telephone',
                value: coverage.details.producerTelephone || null,
            },
            {
                title: 'Producer Address',
                value: getCoverageAddressObjectString(coverage.details.producerAddress) || null,
            },
        ];
    }

    type DetailsKeysWithErrors = Record<string, Record<string, boolean>>;

    @Component({
        components: {
            EndorsementsView,
            EvaluationError,
            FontAwesomeIcon,
            PolicyRecord,
            JsonSchemaView,
            MultiFieldCriterion,
            CollateralList,
            PolicyInfoCarrierInfo,
            PolicyInfoOtherCellsInfo,
            PolicyInfoNonExtraction,
        },
    })
    export default class PolicyInfo extends Vue {
        @Prop({ type: Object, required: true })
        private coverage!: InsuranceInsuredCoverage;

        @Prop({ type: Object, default: null })
        private brokerInfo!: BrokerInfo | null;

        @Prop({ type: Object, default: () => ({}) })
        private evaluationErrors!: Record<string, FieldEvaluationResultError>;

        @Prop({ type: Array, default: () => [] })
        private evaluationResults!: InsuranceEvaluationResult[];

        @Prop({ type: Array, default: () => [] })
        private allCoverages!: InsuranceInsuredCoverageDetails[];

        @Prop({ type: Array as PropType<CollateralEntity[]>, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Prop({ type: Object as PropType<NonExtractionResults>, default: undefined })
        private nonExtractionResults?: NonExtractionResults;

        private faQuestionCircle = faQuestionCircle;
        private multiCoverageTooltipDescription: string = 'Any criteria within this coverage that contains multiple fields are shown here ' +
            '(i.e. General Aggregate Limit covered by Umbrella Excess).';

        private get producerDetails(): { title: string, value: string }[] {
            const producerData = getProducerInfo(this.coverage);
            const brokerData = getBrokerInfo(this.brokerInfo);
            return producerData
                .concat(brokerData)
                .filter(({ value }) => value) as { title: string, value: string }[];
        }

        private get collaterals(): CoverageFieldCollateral[] {
            return this.coverage.details.collateral || [];
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private get currencyLabel(): string {
            return getEnumDefaultDisplayValue(
                EnumCategories.currency,
                this.coverage.policy.currency,
                this.categorizedEnumLabels,
            ) as string;
        }

        private get localCurrencyFormatter(): Intl.NumberFormat {
            const currency = this.coverage?.policy.currency;
            return currencyFormatter(currency);
        }

        private get crossCurrentCoverageCriteria(): Record<string, boolean> {
            const criteria: Record<string, boolean> = {};
            for (const criterion of this.crossCoverageCriteria) {
                const fields = Object.keys(criterion.fields);
                for (const field of fields) {
                    criteria[field] = true;
                }
            }
            return criteria;
        }

        private get values(): Record<string, any> {
            const _values = Object.entries(this.coverage.details)
                .reduce<Record<string, any>>(
                    (result, [ key, value ]) => {
                        const shouldOmitKey =
                            [
                                'coverageStatus',
                                'endorsements',
                                'collateral',
                                'brokerName',
                                'producerName',
                                'producerAddress',
                                'producerContactName',
                                'producerEmail',
                                'producerTelephone',
                            ].includes(key) ||
                            typeOfInsuranceAttributes.includes(key) ||
                            this.crossCurrentCoverageCriteria[key];

                        if (shouldOmitKey) {
                            return result;
                        }

                        let resultKeyValue = this.tryGetDetailsKeysValueWithErrors(key, value);
                        resultKeyValue = this.parseToSingleStringIfAddress(resultKeyValue, this.categorizedEnumLabels);

                        if (!isEmptyValue(resultKeyValue)) {
                            result[key] = getEnumDefaultDisplayValue(key, resultKeyValue, this.categorizedEnumLabels);
                        }

                        return result;
                    },
                    {} as Record<string, any>,
                );

            return transformValuesWithIsPresent(omitBy(_values, (v, k) => this.limitsKeys.includes(k)));
        }

        private get endorsementsFieldsValues(): Record<string, any> {
            if (!this.coverage.details.hasOwnProperty('endorsements')) {
                return {};
            }

            let _endorsementsFieldsValues = {
                ...Object.entries(this.coverage.details.endorsements).reduce((acc, [ key, value ]) => {
                    const shouldOmitKey = shouldBeOmitted(key, value, this.evaluationResults) ||
                        this.crossCurrentCoverageCriteria[key];

                    if (shouldOmitKey) {
                        return acc;
                    }

                    acc[key] = getEnumDefaultDisplayValue(key, value, this.categorizedEnumLabels);
                    return acc;
                }, {} as Record<string, unknown>),
                ...this.nullEndorsementsFieldsWithErrors,
            };
            _endorsementsFieldsValues = combineSpecialCoverageFieldValues(_endorsementsFieldsValues);
            return omitBy(
                _endorsementsFieldsValues,
                (v, k) => this.limitsKeys.includes(k),
            );
        }

        private get complexDetailsKeysWithErrors(): DetailsKeysWithErrors {
            const evaluationErrorDetailsPath = `#/${this.coverage.coverageType}/details/`;
            const pathDelimiter = '/';
            return Object.keys(this.evaluationErrors).reduce<DetailsKeysWithErrors>((result, key) => {
                if (key.startsWith(evaluationErrorDetailsPath)) {
                    const [ complexPropKey, complexPropSubKey ] =
                        key.replace(evaluationErrorDetailsPath, '').split(pathDelimiter);

                    if (complexPropSubKey) {
                        if (!result[complexPropKey]) {
                            result[complexPropKey] = {};
                        }

                        result[complexPropKey][complexPropSubKey] = true;
                    }
                }

                return result;
            }, {} as DetailsKeysWithErrors);
        }

        private get nullEndorsementsFieldsWithErrors() {
            return Object.entries(this.coverage.details.endorsements || {})
                .reduce((acc, [ key, value ]) => {
                    if (
                        value === null &&
                        this.evaluationErrors[`#/${this.coverage.coverageType}/details/endorsements/${key}`] &&
                        !this.crossCurrentCoverageCriteria[key]
                    ) {
                        acc[key] = FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                    return acc;
                }, {} as any);
        }

        private get crossCoverageCriteria(): InsuredMultiFieldCriterion[] {
            const multiFieldCriteria = this.evaluationResults.reduce((acc, evaluationResult) => {
                if (evaluationResult.usedFields.length > 1) {
                    const insuredMultiFieldCriterion: InsuredMultiFieldCriterion = {
                        name: evaluationResult.criterionName,
                        fields: evaluationResult.usedFields.reduce((fields, field) => {
                            const parts = field.split('/');
                            const coverageName = parts.at(1);
                            const name = parts.at(-1);
                            const coverageDetails = this.allCoverages
                                .find((coverage) => coverageName === coverage.coverage?.coverageType)
                                ?.coverage?.details;

                            if (name) {
                                const value = getIn(coverageDetails, parts.slice(3), null);
                                fields[name] = !isNil(value) ?
                                    getEnumDefaultDisplayValue(name, value, this.categorizedEnumLabels) :
                                    FIELD_NOT_AVAILABLE_MESSAGE;
                            }

                            return fields;
                        }, {} as Record<string, unknown>),
                        evaluationError: this.evaluationErrors[evaluationResult.criterionName],
                    };
                    acc.push(insuredMultiFieldCriterion);
                }

                return acc;
            }, [] as InsuredMultiFieldCriterion[]);
            return uniqWith(multiFieldCriteria, isEqual);
        }

        private tryGetDetailsKeysValueWithErrors(key: string, value: unknown): unknown {
            if (value === null) {
                const complexDetailWithErrorsForItsProps = this.complexDetailsKeysWithErrors[key];

                if (complexDetailWithErrorsForItsProps) {
                    return Object.keys(complexDetailWithErrorsForItsProps)
                        .reduce<Record<string, string>>(
                            (_result, _key) => {
                                _result[_key] = FIELD_NOT_AVAILABLE_MESSAGE;
                                return _result;
                            },
                            {} as Record<string, string>,
                        );
                } else if (this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`]) {
                    const keyIsInMultiFieldCriterion = this.crossCoverageCriteria.some((criterion) =>
                        Object.keys(criterion.fields).includes(key),
                    );

                    if (!keyIsInMultiFieldCriterion) {
                        return FIELD_NOT_AVAILABLE_MESSAGE;
                    }
                }
            } else if (Array.isArray(value) && value.length === 0 && this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`]) {
                return FIELD_NOT_AVAILABLE_MESSAGE;
            } else if (!Array.isArray(value) && typeof value === 'object') {
                return Object.entries(value).reduce<Record<string, any>>(
                    (newValue, [ propKey, propValue ]) => {
                        const hasEvaluationError = this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}/${propKey}`];
                        newValue[propKey] = propValue;

                        if (!newValue[propKey] && hasEvaluationError) {
                            newValue[propKey] = FIELD_NOT_AVAILABLE_MESSAGE;
                        }

                        return newValue;
                    },
                    {} as Record<string, any>,
                );
            }

            return value;
        }

        public get limits(): {
            name: string;
            value: number | string | unknown;
            evaluationError: FieldEvaluationResultError | null;
        }[] {
            return this.coverage
                ? [ ...Object.entries(this.coverage.details),
                    ...Object.entries(this.coverage.details.endorsements || {}) ]
                    .filter(([ key ]) => this.isLimit(key))
                    .filter(([ key, value ]) =>
                        !isNil(value) || this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`])
                    .map(([ key, value ]) => ({
                        name: key,
                        value: typeof value === 'number'
                            ? this.localCurrencyFormatter.format(value)
                            : value || FIELD_NOT_AVAILABLE_MESSAGE,
                        evaluationError: this.evaluationErrors[`#/${this.coverage.coverageType}/details/${key}`]
                            || this.evaluationErrors[`#/${this.coverage.coverageType}/details/endorsements/${key}`]
                            || null,
                    })) || []
                : [];
        }

        private get limitsKeys(): string[] {
            return this.limits.map<string>((limit) => limit.name);
        }

        private isLimit(key: string) {
            return key.toLowerCase().endsWith('limit') || key.toLowerCase().includes('singlelimit')
                || key === 'deductible';
        }

        private isMultiCoverageField(property: { [key: string]: JsonSchema } | {}): boolean {
            return 'properties' in property;
        }

        private get singleCoverageFields() {
            const schema = getBasicSchemaByValue(this.values);
            return {
                ...schema,
                properties: 'properties' in schema
                    ? omitBy(schema.properties, this.isMultiCoverageField)
                    : {},
            };
        }

        private get singleCoverageEndorsementsFields() {
            const schema = getBasicSchemaByValue(this.endorsementsFieldsValues);
            return {
                ...schema,
                properties: 'properties' in schema
                    ? omitBy(schema.properties, this.isMultiCoverageField)
                    : {},
            };
        }

        private get multiPropsCoverageFields() {
            const schema = getBasicSchemaByValue(this.values);
            return {
                ...schema,
                properties: 'properties' in schema
                    ? pickBy(schema.properties, this.isMultiCoverageField)
                    : {},
            };
        }

        private get multiCoverageEndorsementsFields() {
            const schema = getBasicSchemaByValue(this.endorsementsFieldsValues);
            return {
                ...schema,
                properties: 'properties' in schema
                    ? pickBy(schema.properties, this.isMultiCoverageField)
                    : {},
            };
        }

        private get numberOfMultiPropsCoverageFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiPropsCoverageFields.properties.hasOwnProperty(key)).length;
        }

        private get numberOfEndorsementsMultiCoverageFields(): number {
            return Object.keys(this.values).filter((key) =>
                this.multiCoverageEndorsementsFields.properties.hasOwnProperty(key)).length;
        }

        private get coverageStatus(): boolean {
            return this.coverage.details.coverageStatus ?? false;
        }

        private getEvaluationResult(url: string): FieldEvaluationResultError | null {
            return this.evaluationErrors[`#/${this.coverage.coverageType}/${url}`] || null;
        }

        private goToCriterion(coverageCriteriaGroupId: string): void {
            this.$emit('goToCriterion', coverageCriteriaGroupId, this.coverage.coverageType);
        }

        private grantExceptionToCriterion(criterionId: string): void {
            this.$emit('grantExceptionToCriterion', criterionId);
        }

        private grantEvaluateEntityException(exception: InsuranceExceptionInput[]): void {
            this.$emit('grantEvaluateEntityException', exception);
        }

        private removeExceptions(exceptionIds: string[]): void {
            this.$emit('removeExceptions', exceptionIds);
        }

        private parseToSingleStringIfAddress(value: unknown, enums: CategorizedEnumLabels): unknown | string {
            const addressTypes = [
                InsuranceCoverageObjectType.address,
                InsuranceCoverageObjectType.addressV2,
                InsuranceCoverageObjectType.coverageFieldAddress,
            ];
            if (typeof value === 'object' &&
                value &&
                '$objectType' in value &&
                addressTypes.includes((value as any).$objectType)
            ) {
                return getCoverageAddressObjectString(value as Record<string, any>, enums.countryCode);
            } else {
                return value;
            }
        }

        private get isShowingCarrierInfo(): boolean {
            return isShowingCarrierInfo(this.coverage.policy.carrier, this.getEvaluationResult);
        }
    }
</script>
