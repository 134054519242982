<template>
    <div class="InsuredActionsTable">
        <Table>
            <template v-slot:head>
                <HeadCell class="ActionReview__valueHeader">
                    Value
                </HeadCell>
                <HeadCell class="InsuredActionsTable__actionRequiredHeader">
                    Action Required
                </HeadCell>
                <HeadCell class="InsuredActionsTable__TypeHeader">
                    Type
                </HeadCell>
                <HeadCell sortable class="InsuredActionsTable__dateCreatedHeader"
                    :sort="getSort('createdAt')"
                    @sort="changeSort('createdAt', $event)"
                >
                    Date Created
                </HeadCell>
            </template>
            <InsuredActionsTableRow
                v-for="insuredAction of insuredActions"
                :key="insuredAction.id"
                :insured-action="insuredAction"
                @review="$emit('review', $event)"
            />
            <template v-if="insuredActions.length === 0" v-slot:status>
                <span>{{ emptyReviewsText }}</span>
            </template>
        </Table>
        <div v-if="insuredActions.length > 0" class="InsuredActionsTable__info">
            Showing up to {{ recordLimit }} actions
        </div>
    </div>
</template>

<style lang="scss">
    .InsuredActionsTable {
        .Table__status {
            min-height: 250px;
            background-color: #fff;
        }

        &__info {
            margin-top: 15px;
            color: #7d8792;
            text-align: center;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { HeadCell, Table } from '@evidentid/dashboard-commons/components/Table';
    import { InsuranceInsuredActionDetails } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import InsuredActionsTableRow
        from '@/modules/insured-actions-review/components/InsuredActionsTableRow/InsuredActionsTableRow.vue';
    import { Sorting, SortingDirection } from '@evidentid/universal-framework/sorting';

    @Component({
        components: {
            Button, Dropdown, HeadCell, InsuredActionsTableRow, Table,
        },
    })
    export default class InsuredActionsTable extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredActions!: InsuranceInsuredActionDetails[];

        @Prop({ type: Object })
        private sort!: Sorting;

        @Prop({ type: String })
        private noActionMessage?: string;

        private changeSort(column: string, direction: SortingDirection): void {
            this.$emit('sort', { column, direction });
        }

        private getSort(column: string): SortingDirection | undefined {
            return this.sort?.column === column ? this.sort?.direction : undefined;
        }

        private get recordLimit(): string {
            return (this.$route.query?.limit) as string || '50';
        }

        private get emptyReviewsText(): string {
            return this.noActionMessage || 'All available actions have been completed.';
        }
    }
</script>
