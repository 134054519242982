import {
    InsuranceEvaluatedCollateralEntity,
    InsuranceEvaluatedEntityMappingAction,
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionType,
    InsuranceInsuredEndorsementFormNumberAction,
    InsuranceInsuredEntityNameMatchingAction,
    InsuranceNonExtractionResolutionAction,
    InsuranceComplianceWithCategoryResolutionAction,
} from '@evidentid/insurance-facing-lib/models/insured-actions-review';

function getEvaluatedEntityMappingActionValue(entityMappingAction: InsuranceEvaluatedEntityMappingAction): string {
    // [entityMappingExpansion]: currently only collateral type support, when expanded, add cases per type
    const requiredEntity = entityMappingAction.requiredEntity as InsuranceEvaluatedCollateralEntity;
    return requiredEntity.description;
}

export function getActionValue(insuredActionDetails: InsuranceInsuredActionDetails): any {
    switch (insuredActionDetails.action.$action) {
        case InsuranceInsuredActionType.entityNameMatchingV1:
            return (insuredActionDetails.action as InsuranceInsuredEntityNameMatchingAction).given;
        case InsuranceInsuredActionType.endorsementFormNumberV1:
            return (insuredActionDetails.action as InsuranceInsuredEndorsementFormNumberAction).formNumber;
        case InsuranceInsuredActionType.evaluatedEntityMappingV1:
            return getEvaluatedEntityMappingActionValue(
                insuredActionDetails.action as InsuranceEvaluatedEntityMappingAction,
            );
        case InsuranceInsuredActionType.nonExtractionCriterionResolutionV1:
            return (insuredActionDetails.action as InsuranceNonExtractionResolutionAction).insuredName;
        case InsuranceInsuredActionType.complianceWithCategoryResolution:
            return (insuredActionDetails.action as InsuranceComplianceWithCategoryResolutionAction).providedValue;
        default:
            return null;
    }
}
