<template>
    <Row hoverable class="InsuredActionsTableRow" @click="$emit('review', insuredAction.id)">
        <Cell v-if="actionValue != null && actionValue !==''" class="InsuredActionsTableRow__value">
            {{ actionValue }}
        </Cell>
        <EmptyValueDashSymbol v-else />
        <Cell class="InsuredActionsTableRow__actionRequired">
            {{ actionRequired }}
        </Cell>
        <Cell class="InsuredActionsTableRow__type">
            {{ actionScope }}
        </Cell>
        <Cell class="InsuredActionsTableRow__dateCreated">
            {{ insuredAction.createdAt }}
        </Cell>
    </Row>
</template>

<style lang="scss">
    .InsuredActionsTableRow {
        cursor: pointer;

        &__emailText {
            font-size: 11px;
            display: block;
            color: #99adbd;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        InsuranceInsuredActionDetails,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faMinus } from '@fortawesome/free-solid-svg-icons';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { Todo } from '@evidentid/dashboard-commons/components/Todo';
    import { Expandable } from '@evidentid/dashboard-commons/components/Expandable';
    import { Menu, MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import { getActionRequired } from '@/modules/insured-actions-review/utils/getActionRequired';
    import { getActionValue } from '@/modules/insured-actions-review/utils/getActionValue';
    import { getActionScopeType } from '@/modules/insured-actions-review/utils/getActionScopeType';
    import EmptyValueDashSymbol
        from '@/modules/insured-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';

    @Component({
        components: { EmptyValueDashSymbol, Cell, Expandable, FontAwesomeIcon, MenuLink, Menu, Row, Todo },
    })
    export default class InsuredActionsTableRow extends Vue {
        @Prop({ type: Object })
        private insuredAction!: InsuranceInsuredActionDetails;

        private faMinus = faMinus;

        private get actionRequired(): string {
            return getActionRequired(this.insuredAction);
        }

        private get actionValue(): any {
            return getActionValue(this.insuredAction);
        }

        private get actionScope(): string {
            return getActionScopeType(this.insuredAction);
        }
    }
</script>
