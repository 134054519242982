<template>
    <LoadingModal
        v-if="status === 'loading' || status === 'uninitialized'"
        additional-class-name="InsuredsCoisModal InsuredsCoisModal--loading"
        header="COIs"
        @close="close"
    />
    <InsuredsCoisErrorModal v-else-if="status === 'error'" @close="close" />
    <InsuredsCoisEmptyModal
        v-else-if="filteredCertificates.length === 0"
        :email="email"
        @close="close"
    />
    <InsuredsCoisListModal
        v-else
        :email="email"
        :certificates="filteredCertificates"
        :coverage-models="coverageModels"
        @close="close"
        @open="open"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { CoiDetails } from '@/modules/insured-certificates/types';
    import InsuredsCoisErrorModal from '@/modules/insured-certificates/components/InsuredsCoisErrorModal.vue';
    import InsuredsCoisEmptyModal from '@/modules/insured-certificates/components/InsuredsCoisEmptyModal.vue';
    import InsuredsCoisListModal from '@/modules/insured-certificates/components/InsuredsCoisListModal.vue';
    import CoiDetailsModal from '@/modules/insured-certificates/components/CoiDetailsModal.vue';
    import { hasDocument } from '@/modules/insured-certificates/services/certificateUtilities';
    import { InsuranceCoverageModel } from '@evidentid/rpweb-api-client/types';

    @Component({
        components: {
            LoadingModal,
            CoiDetailsModal,
            InsuredsCoisListModal,
            InsuredsCoisEmptyModal,
            InsuredsCoisErrorModal,
        },
    })
    export default class InsuredsCoisModal extends Vue {
        @Prop({ type: String })
        private status!: OperationStatus;

        @Prop({ type: String })
        private email!: string;

        @Prop({ type: String })
        private customEmptyMessage!: string;

        @Prop({ type: Array, default: () => [] })
        private certificates!: CoiDetails[];

        @Prop({ type: Array })
        private coverageModels!: InsuranceCoverageModel[];

        private get filteredCertificates(): CoiDetails[] {
            return this.certificates.filter(hasDocument);
        }

        private open(coi: CoiDetails): void {
            this.$emit('open', coi);
        }

        private close(): void {
            this.$emit('close');
        }
    }
</script>
