<template>
    <div class="NonExtractionReview">
        <div class="NonExtractionReview__container">
            <div class="NonExtractionReview__form">
                <Table class="NonExtractionReview__table">
                    <template #head>
                        <HeadCell
                            data-test-id="NonExtractionReview__fieldHeader"
                            class="NonExtractionReview__fieldHeader"
                        >
                            Field
                        </HeadCell>
                        <HeadCell data-test-id="NonExtractionReview__valueHeader">
                            Value
                        </HeadCell>
                        <HeadCell
                            data-test-id="NonExtractionReview__decisionHeader"
                            class="NonExtractionReview__decisionHeader"
                        >
                            Decision
                        </HeadCell>
                    </template>
                    <Row
                        v-for="(requirement, index) in actionDetails.action.requirements"
                        :key="`${actionDetails.action.field}-${index}`"
                        hoverable
                    >
                        <Cell
                            data-test-id="NonExtractionReview__fieldRowCell"
                            class="NonExtractionReview__fieldRowCell"
                        >
                            {{ actionField }}
                        </Cell>
                        <Cell data-test-id="NonExtractionReview__valueRowCell">
                            <span>
                                {{ requirement }}
                            </span>
                        </Cell>
                        <Cell
                            data-test-id="NonExtractionReview__decisionRowCell"
                            class="NonExtractionReview__decisionRowCell"
                        >
                            <Dropdown
                                :selected="selectedOptions"
                                :options="options"
                                placeholder="Select Status"
                                @input="selectOption($event, requirement)"
                            />
                        </Cell>
                    </Row>
                </Table>
                <div class="flex justify-end">
                    <Button
                        data-test-id="NonExtractionReview__cancelButton"
                        @click="onCancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        class="NonExtractionReview__saveButton"
                        data-test-id="NonExtractionReview__saveButton"
                        type="primary"
                        :disabled="saveDisabled"
                        @click="onSave"
                    >
                        Save
                    </Button>
                </div>
            </div>

            <div class="NonExtractionReview__documents">
                <LogoLoader v-if="loading" />
                <DocumentsViewer v-if="!loading" :documents="docsStatus.list" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { CoiDocumentStatus } from '@/modules/insured-actions-review/vuex';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import {
        InsuranceInsuredActionType,
        InsuranceNonExtractionResolutionActionDetails,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import DocumentsViewer from '@/modules/insured-actions-review/components/DocumentsViewer/DocumentsViewer.vue';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { PropType } from 'vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        NonExtractionCriterionResolutionV1ResolveInput,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review/InsuranceActionResolveInput.model';

    export default Vue.extend({
        name: 'NonExtractionReview',
        components: {
            Button,
            Dropdown,
            DocumentsViewer,
            HeadCell,
            LogoLoader,
            Table,
            Cell,
            Row,
        },
        props: {
            actionDetails: {
                type: Object as PropType<InsuranceNonExtractionResolutionActionDetails>,
                default: () => ({} as InsuranceNonExtractionResolutionActionDetails),
            },
        },
        data() {
            return {
                selectedOptions: [],
                options: [ { label: 'Accept', value: true }, { label: 'Reject', value: false } ],
                showConfirmationModal: false,
                requirementResults: {} as Record<string, boolean | undefined>,
            };
        },
        computed: {
            rpName(): string {
                return this.$rp.current!;
            },
            docsStatus(): CoiDocumentStatus {
                if (this.$store.state.insuredActionsReview.coiDocuments[this.rpName] &&
                    this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id]) {
                    return this.$store.state.insuredActionsReview.coiDocuments[this.rpName][this.actionDetails.id];
                } else {
                    return { status: OperationStatus.uninitialized, list: [] };
                }
            },
            loading(): boolean {
                return this.docsStatus.status === OperationStatus.loading;
            },
            saveDisabled(): boolean {
                return this.actionDetails.action.requirements.some(
                    (requirement: string) => !(requirement in this.requirementResults) ||
                        this.requirementResults[requirement] === undefined,
                );
            },
            actionField(): string {
                if (this.actionDetails.action.field === 'exclusions') {
                    return 'Exclusion';
                } else if (this.actionDetails.action.field === 'projectRequirements') {
                    return 'Project Requirement';
                } else {
                    return '-';
                }
            },
        },
        watch: {
            actionDetails: {
                immediate: true,
                handler() {
                    if (this.docsStatus.status === OperationStatus.uninitialized &&
                        this.actionDetails.evaluationResults.length > 0 &&
                        this.actionDetails.action.verificationId) {
                        this.$store.actions.insuredActionsReview.loadCoiDocuments({
                            rpName: this.rpName,
                            actionId: this.actionDetails.id,
                            verificationIds: [ this.actionDetails.action.verificationId ],
                        });
                    }
                },
            },
        },
        methods: {
            onSave(): void {
                if (!this.saveDisabled) {
                    const data: NonExtractionCriterionResolutionV1ResolveInput = {
                        $action: InsuranceInsuredActionType.nonExtractionCriterionResolutionV1,
                        requirementResults: this.requirementResults,
                    };
                    this.$emit('resolve', data);
                }
            },
            onCancel(): void {
                this.$emit('cancel');
            },
            selectOption(option: DropdownOption, requirement: string): void {
                this.requirementResults = { ...this.requirementResults, [requirement]: option.value };
            },
        },
    });
</script>
