<!-- eslint-disable max-lines -->
<!--TODO(PRODUCT-17632): refactor and make this file smaller-->
<template>
    <Page :loading="rpChangeCount > 0" title="Insureds">
        <template v-if="!loadingCategoryStatistics && categoryStatistics.total === 0">
            <InsuredsEmptyState
                :loading="loadingInsuredFields || loadingCoverageTypes"
                @downloadSampleCsv="downloadSampleCsv"
                @openAddIndividualInsuredModal="openAddIndividualInsuredModal"
                @openBulkImportInsuredsModal="openBulkImportInsuredsModal"
            />
        </template>
        <template v-else-if="selectedInsured">
            <InsuredDetails
                :currently-updated-insureds-ids="insuredLoadingIdList"
                :coverage-details-list="coverageDetailsListStatus.list"
                :collateral-entities="collateralEntitiesStatus.list"
                :insured-coverage-criteria-groups="insuredCoverageCriteriaGroupsStatus.list"
                :insured="selectedInsured"
                :insured-fields="insuredFieldsStatus.list"
                :is-loading-submission-link="loadingSubmissionLink"
                :submission-link-per-insured-id="submissionLinkPerInsuredId"
                :loading="loadingInsuredDetails || loadingInsuredCoverageCriteriaGroups"
                :is-collateral-enabled="isCollateralEnabled"
                :coverage-models="getCoverageModelsWithCountryLabel(selectedInsured)"
                :external-alert-config="insuredDetailsAlertConfig"
                :collateral-insured-field-key="collateralInsuredFieldKey"
                :enum-labels="categorizedEnumLabels"
                @close="closeInsuredDetails"
                @goToCriterion="goToCriterion"
                @patchInsured="patchInsureds"
                @updateInsureds="updateInsureds"
                @deactivateInsureds="deactivateInsureds"
                @setInsuredsPauseState="setInsuredsPauseState"
                @sendCoiRequest="onSendCoiRequestFromInsuredDetails"
                @copySubmissionLink="copySubmissionLink"
                @loadInsuredRequests="onActionsButtonClicked"
                @evaluationUpdated="onEvaluationUpdated"
                @open-historic-coi-modal="openHistoricCoiModal"
            />
        </template>
        <template v-else>
            <DashboardAlertList :configs="alertConfigList" />
            <CategoryTiles
                v-if="insuredStatisticsStatus.statistics"
                :value="categoryStatistics"
                :selected="selectedCategory"
                :disabled="insuredsStatus.loading"
                @input="onSelectCategory"
            />
            <InfiniteScroll @scroll="loadNextInsureds">
                <InsuredsTable
                    :insureds="insuredsStatus.list"
                    :finished="insuredsStatus.finished"
                    :loading="insuredsStatus.loading"
                    :exporting="isExporting"
                    :sort="sorting"
                    :displayed-insured-fields="insuredFieldsToDisplay"
                    :currently-updated-insureds-ids="insuredLoadingIdList"
                    :is-performing-bulk-action="patchInsuredsStatus.isBulk"
                    :is-loading-submission-link="loadingSubmissionLink"
                    :submission-link-per-insured-id="submissionLinkPerInsuredId"
                    :coverage-criteria-groups="coverageCriteriaGroupsStatus.list"
                    @copySubmissionLink="copySubmissionLink"
                    @loadInsuredRequests="onActionsButtonClicked"
                    @selectInsured="showInsuredDetails"
                    @sort="changeSorting"
                    @export="exportInsureds"
                    @openAddIndividualInsuredModal="openAddIndividualInsuredModal"
                    @openBulkImportInsuredsModal="openBulkImportInsuredsModal"
                    @showCois="showCois"
                    @deactivateInsureds="deactivateInsureds"
                    @setInsuredsPauseState="setInsuredsPauseState"
                    @clearFilters="clearFilters"
                    @sendCoiRequest="onSendCoiRequestFromInsuredsTable"
                    @grantExceptionToInsured="grantException"
                    @removeExceptions="removeExceptions"
                    @open-historic-coi-modal="openHistoricCoiModal"
                >
                    <template #toolbar>
                        <InsuredSearchAndFilter
                            :filters="filters"
                            :coverage-criteria-groups="coverageCriteriaGroupsStatus.list"
                            :effective-groups="effectiveGroupsStatus.list"
                            :insured-fields="insuredFieldsOther"
                            :disabled="insuredsStatus.loading"
                            :collateral-insured-field-key="collateralInsuredFieldKey"
                            :insured-field-collaterals="insuredFieldCollaterals"
                            @change="changeFilters"
                        />
                        <FiltersChipList
                            v-if="hasAnyDisplayableFilters"
                            :filters="filters"
                            :insured-fields="insuredFieldsOther"
                            :disabled="insuredsStatus.loading"
                            :collateral-insured-field-key="collateralInsuredFieldKey"
                            @closeFilters="closeFilters"
                        />
                    </template>
                </InsuredsTable>
            </InfiniteScroll>
        </template>
    </Page>
</template>

<script lang="ts">
    /* eslint-disable max-lines */
    import { isEmpty, pick, pickBy, omit, sortBy, uniq } from 'lodash';
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import {
        InsuranceCoverageModel,
        InsuranceDashboardConfiguration,
        InsuranceInsured,
        InsuranceInsuredCoverageCriteriaGroup,
        InsuranceInsuredCoverageDetails,
        InsuranceInsuredField,
        InsuranceInsuredInput,
        InsuranceInsuredStatistics,
        InsuranceRequestsConfig,
        InsuranceVerificationRequest,
        InsuranceVerificationStatus,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { InfiniteScroll } from '@evidentid/dashboard-commons/components/InfiniteScroll';
    import {
        deserializeSorting,
        serializeMultiSorting,
        serializeSorting,
        Sorting,
        SortingDirection,
    } from '@evidentid/universal-framework/sorting';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertConfig, AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import Page from '@/layouts/Page.vue';
    import { downloadSampleCsv } from '@/utils/csv-sample/sample-values';
    import {
        BatchAddInsuredsStatus,
        BatchUpdateInsuredsStatus,
        PatchInsuredsStatus,
    } from '@/modules/insured-management/vuex';
    import CategoryTiles from '@/modules/insured-filtering/components/CategoryTiles/CategoryTiles.vue';
    import InsuredSearchAndFilter
        from '@/modules/insured-filtering/components/InsuredSearchAndFilter/InsuredSearchAndFilter.vue';
    import { InsuredFilterCategory, InsuredFilters } from '@/modules/insured-filtering/types';
    import {
        categoryFilterMap,
        getCategoryByFilters,
        getInsuredCollateralFieldFiltersKeys,
        getInsuredFieldFilterKeys,
        getInsuredStandardFilterKeys,
        parseFiltersForApi,
    } from '@/modules/insured-filtering/utils/insureFilterUtils';
    import InsuredsTable from '@/modules/dashboard/components/InsuredsTable/InsuredsTable.vue';
    import InsuredsEmptyState from '@/modules/dashboard/components/InsuredsEmptyState/InsuredsEmptyState.vue';
    import { InsuredPatch, PatchOperationType } from '@evidentid/rpweb-api-client';
    import FiltersChipList from '@/modules/insured-filtering/components/ActiveFiltersChipList/FiltersChipList.vue';
    import InsuredDetails from '@/modules/insured-details/components/InsuredDetails/InsuredDetails.vue';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { SendRequestStatus } from '@/modules/insured-request/vuex';
    import { createEmptyRequestsConfigStatus } from '@/modules/dashboard/vuex/dashboard';
    import { createInsuredLevelException } from '@/modules/insured-details/utils/createException';
    import {
        CoverageCriteriaGroupsStatus,
        CoverageModelsStatus,
        InsuredEffectiveGroupStatus,
    } from '@/modules/decisioning-criteria/vuex';
    import DashboardAlertList from '@/modules/dashboard/components/DashboardAlertList/DashboardAlertList.vue';
    import { ActionableAlertConfig } from '@/modules/insured-details/components/ActionableAlert/types';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';
    import { shouldEnableCoiRequest } from '@/utils/shouldEnableCoiRequest/shouldEnableCoiRequest';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import { getCoverageTypeLabelByCountry } from '@/utils/getCoverageTypeLabelByCountry/getCoverageTypeLabelByCountry';

    type AlertConfigName =
        'alertConfig'
        | 'additionalAlertConfig'
        | 'insuredDetailsAlertConfig'
        | 'collectEvidenceAlert'
        | 'errorAlertConfig';
    type AlertTimeoutName =
        'alertConfigTimeout'
        | 'additionalAlertConfigTimeout'
        | 'insuredDetailsAlertTimeout'
        | 'collectEvidenceAlertTimeout'
        | 'errorAlertTimeout';
    type FinishCoiRequestCallbackFnc = (...args: unknown[]) => Promise<unknown>;

    @Component({
        components: {
            DashboardAlertList, Page, InfiniteScroll, InsuredsTable, Alert, FontAwesomeIcon, InsuredsEmptyState,
            CategoryTiles, InsuredSearchAndFilter, FiltersChipList, InsuredDetails, LogoLoader,
        },
    })
    export default class InsuranceDashboard extends Vue {
        private readonly defaultSorting = { column: 'displayName', direction: SortingDirection.asc };
        private lastRpName!: string;
        private alertConfig: AlertConfig | null = null;
        private alertConfigTimeout: any = null;
        private additionalAlertConfig: AlertConfig | null = null;
        private additionalAlertConfigTimeout: any = null;
        private insuredDetailsAlertConfig: ActionableAlertConfig | null = null;
        private insuredDetailsAlertTimeout: any = null;
        private collectEvidenceAlert: AlertConfig | null = null;
        private collectEvidenceAlertTimeout: any = null;
        private errorAlertConfig: AlertConfig | null = null;
        private errorAlertTimeout: any = null;
        private insuredFieldsToDisplay: InsuranceInsuredField[] = [];
        private rpChangeCount: number = 0;
        private loadingSubmissionLink: boolean = false;

        @Watch('$rp.current', { immediate: true })
        private async handleRpChange(rpName: string, prevRpName: string) {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            ++this.rpChangeCount;

            if (prevRpName) {
                this.$store.actions.dashboard.clearInsuredFieldsList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearCoverageTypeList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearInsuredsList({ rpName: prevRpName });
                this.$store.actions.dashboard.clearInsuredStatistics({ rpName: prevRpName });
                this.$store.actions.dashboard.clearInsuredRequests({ rpName: prevRpName });
                this.$store.actions.dashboard.clearSubmissionLinks({ rpName: prevRpName });
                this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearRpInsuredCriteriaGroups({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearCoverageCriteriaGroups({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearEffectiveGroupsStatus({ rpName: prevRpName });
                this.$store.actions.decisioningCriteria.clearCoverageModelsStatus({ rpName: prevRpName });
                this.closeInsuredDetails();
            }
            // reloadInsured checks for filter which is relying on insuredFields to be loaded first
            await this.loadInsuredFields();
            await Promise.all([
                this.loadCoverageTypes(),
                this.reloadInsureds(),
                this.loadInsuredStatistics(),
                this.loadDashboardConfiguration(),
                this.loadCoverageCriteriaGroups(),
                this.loadEffectiveGroups(),
                this.loadCoverageModels(),
            ]);
            const insuredId = this.$route.query?.insured || null;
            if (insuredId && typeof insuredId === 'string') {
                await this.$store.actions.dashboard.loadInsured({ rpName: this.rpName, id: insuredId });
                await this.loadInsuredCoverageData({ rpName: this.rpName, insuredId });
            }
            --this.rpChangeCount;
        }

        @Watch('$route.query')
        private async handleQueryChange() {
            // Reload results
            const insuredId = this.$route.query?.insured || null;
            if (!insuredId) {
                this.reloadInsureds();
            } else if (typeof insuredId === 'string') {
                await this.loadInsuredCoverageData({ rpName: this.rpName, insuredId });
            }
        }

        private async loadInsuredCoverageData(
            payload: { rpName: string, insuredId: string },
        ): Promise<void[]> {
            return Promise.all([
                this.$store.actions.insuredDetails.loadCoverageDetailsList(payload),
                this.$store.actions.insuredDetails.loadCollateralEntities(payload),
                this.loadInsuredCoverageCriteriaGroups(),
            ]);
        }

        private async loadNextInsureds() {
            if (!this.insuredsStatus.loading && !this.insuredsStatus.finished) {
                const apiFilters = pickBy(this.filters, Boolean);
                const modifiedSort = (this.sorting && this.sorting.column === 'complianceStatus')
                    ? serializeMultiSorting([ { column: 'active', direction: SortingDirection.desc }, this.sorting ])
                    : serializeSorting(this.sorting);
                await this.$store.actions.dashboard.loadNextInsureds({
                    rpName: this.rpName,
                    sort: modifiedSort || null,
                    filters: parseFiltersForApi(
                        apiFilters,
                        this.insuredFieldsOther,
                        this.collateralInsuredFieldKey,
                        this.insuredFieldCollaterals,
                    ),
                });
            }
        }

        private async loadInsuredStatistics() {
            if (this.insuredStatisticsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.dashboard.loadInsuredStatistics({ rpName: this.rpName });
            }
        }

        private async loadInsuredFields() {
            if (!this.loadingInsuredFields) {
                await this.$store.actions.dashboard.loadInsuredFields({ rpName: this.rpName });
            }
        }

        private async loadCoverageTypes() {
            if (!this.loadingCoverageTypes) {
                await this.$store.actions.dashboard.loadCoverageTypes({ rpName: this.rpName });
            }
        }

        private async loadDashboardConfiguration() {
            if (!this.loadingDashboardConfiguration) {
                await this.$store.actions.dashboardConfiguration.loadDashboardConfiguration({ rpName: this.rpName });
            }
        }

        private async loadCoverageCriteriaGroups() {
            if (this.coverageCriteriaGroupsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.loadCoverageCriteriaGroups({ rpName: this.rpName });
            }
        }

        private async loadEffectiveGroups() {
            if (this.effectiveGroupsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.decisioningCriteria.loadEffectiveGroups({ rpName: this.rpName });
            }
        }

        private async loadCoverageModels() {
            if (this.coverageModelsStatus.status !== OperationStatus.loading) {
                this.$store.actions.decisioningCriteria.loadCoverageModels({ rpName: this.rpName });
            }
        }

        private async loadInsuredCoverageCriteriaGroups() {
            if (!this.loadingInsuredCoverageCriteriaGroups && this.selectedInsured) {
                await this.$store.actions.decisioningCriteria.loadInsuredCoverageCriteriaGroups({
                    rpName: this.rpName,
                    insuredId: this.selectedInsured.id,
                });
            }
        }

        @Watch('insuredFieldsStatus')
        private handleInsuredFieldsStatusChange() {
            this.loadInsuredFieldsForDisplay();
        }

        @Watch('dashboardConfiguration')
        private handleDashboardConfigurationChange() {
            this.loadInsuredFieldsForDisplay();
        }

        private loadInsuredFieldsForDisplay() {
            if (this.insuredFieldsStatus.status === OperationStatus.success &&
                this.dashboardConfiguration.status === OperationStatus.success) {
                this.insuredFieldsToDisplay = this.dashboardConfiguration.config.insuredFields
                    .map((fieldId: string) => this.insuredFieldsStatus.list.find((field) => field.id === fieldId))
                    .filter(Boolean) as InsuranceInsuredField[];
            }
        }

        private get loadingCategoryStatistics(): boolean {
            return this.insuredStatisticsStatus.status === OperationStatus.loading;
        }

        private get loadingInsuredFields(): boolean {
            return this.insuredFieldsStatus.status === OperationStatus.loading;
        }

        private get loadingCoverageTypes(): boolean {
            return this.coverageTypesStatus.status === OperationStatus.loading;
        }

        private get loadingDashboardConfiguration(): boolean {
            return this.dashboardConfiguration.status === OperationStatus.loading;
        }

        private get loadingInsuredDetails(): boolean {
            return this.coverageDetailsListStatus.status === OperationStatus.loading;
        }

        private get loadingCollateralEntities(): boolean {
            return this.collateralEntitiesStatus.status === OperationStatus.loading;
        }

        private get loadingInsuredCoverageCriteriaGroups(): boolean {
            return this.insuredCoverageCriteriaGroupsStatus.status === OperationStatus.loading;
        }

        private get rpName(): string {
            return this.$rp?.current || '';
        }

        private get insuredsStatus() {
            return this.$store.state.dashboard.insureds[this.rpName] || {
                count: Infinity,
                finished: false,
                loading: false,
                list: [],
            };
        }

        private get insuredFieldsStatus(): { status: OperationStatus, list: InsuranceInsuredField[] } {
            return this.$store.state.dashboard.insuredFields[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get insuredFieldsOther(): InsuranceInsuredField[] {
            return this.insuredFieldsStatus.list.filter((insuredField) =>
                !isCollateralInsuredField(insuredField),
            );
        }

        private get insuredFieldCollaterals(): InsuranceInsuredField | null {
            return this.insuredFieldsStatus.list.find(isCollateralInsuredField) || null;
        }

        private get collateralInsuredFieldKey(): string {
            return this.insuredFieldCollaterals?.key || '';
        }

        private get coverageTypesStatus(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.coverageTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get dashboardConfiguration(): {
            status: OperationStatus;
            config: InsuranceDashboardConfiguration;
        } {
            return this.$store.state.dashboardConfiguration.configuration[this.rpName] || {
                status: OperationStatus.uninitialized,
                config: { insuredFields: [] },
            };
        }

        private get insuredStatisticsStatus(): { status: OperationStatus, statistics: InsuranceInsuredStatistics } {
            return this.$store.state.dashboard.insuredStatistics[this.rpName] || {
                status: OperationStatus.uninitialized,
                statistics: {
                    compliance: {
                        forcePlaced: 0,
                        compliant: 0,
                        nonCompliant: 0,
                        pending: 0,
                        new: 0,
                    },
                    expiration: {
                        within30Days: 0,
                    },
                },
            };
        }

        private get categoryStatistics(): Record<InsuredFilterCategory | 'total', number> {
            const { compliance, expiration } = this.insuredStatisticsStatus.statistics;
            return {
                [InsuredFilterCategory.compliantAndNotExpiringSoon]: compliance.compliant - expiration.within30Days,
                [InsuredFilterCategory.nonCompliant]: compliance.nonCompliant,
                [InsuredFilterCategory.pendingAndNew]: compliance.pending + compliance.new,
                [InsuredFilterCategory.compliantAndExpiringSoon]: expiration.within30Days,
                total: Object.entries(compliance).reduce((acc, [ , value ]) => acc + value, 0),
            };
        }

        private get exportStatus(): OperationStatus {
            return this.$store.getStatus('exportInsureds', 'exportStatus', this.rpName);
        }

        private get isExporting(): boolean {
            return this.exportStatus === OperationStatus.loading;
        }

        private get sorting(): Sorting {
            return deserializeSorting(this.$route.query.sort) || this.defaultSorting;
        }

        private get filters(): InsuredFilters {
            const standardFilters = pick(this.$route.query, getInsuredStandardFilterKeys());
            let insuredFieldFilters = {};
            let collateralFieldFilters = {};

            if (this.insuredFieldsStatus.status === OperationStatus.success) {
                insuredFieldFilters = pick(
                    this.$route.query,
                    getInsuredFieldFilterKeys(this.insuredFieldsOther),
                ) as Record<string, string>;
                collateralFieldFilters = pick(
                    this.$route.query,
                    getInsuredCollateralFieldFiltersKeys(this.collateralInsuredFieldKey),
                ) as Record<string, string>;
            }

            const filters = { ...standardFilters };

            if (!isEmpty(insuredFieldFilters)) {
                Object.assign(filters, { insuredFieldFilters });
            }

            if (!isEmpty(collateralFieldFilters)) {
                Object.assign(filters, { collateralFieldFilters });
            }

            return filters;
        }

        private get hasAnyDisplayableFilters(): boolean {
            const { search, ...filters } = this.filters;
            return Object.keys(filters).length > 0;
        }

        private get selectedCategory(): InsuredFilterCategory[] {
            const category = getCategoryByFilters(this.filters);
            return category ? [ category ] : [];
        }

        private get addInsuredsStatus(): BatchAddInsuredsStatus {
            return this.$store.state.insuredManagement.addInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateInsuredsStatus(): BatchUpdateInsuredsStatus {
            return this.$store.state.insuredManagement.updateInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get patchInsuredsStatus(): PatchInsuredsStatus {
            return this.$store.state.insuredManagement.patchInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
                currentlyUpdated: [],
                isBulk: false,
            };
        }

        private get sendRequestStatus(): SendRequestStatus {
            return this.$store.state.insuredRequest.sendCoiRequestStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                insuredIds: [],
                count: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get coverageDetailsListStatus(): { status: OperationStatus, list: InsuranceInsuredCoverageDetails[] } {
            return this.$store.state.insuredDetails.coverageDetailsListStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get collateralEntitiesStatus(): { status: OperationStatus, list: CollateralEntity[] } {
            return this.$store.state.insuredDetails.collateralEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get effectiveGroupsStatus(): InsuredEffectiveGroupStatus {
            return this.$store.state.decisioningCriteria.effectiveGroupStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get coverageModelsStatus(): CoverageModelsStatus {
            return this.$store.state.decisioningCriteria.coverageModelsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get insuredCoverageCriteriaGroupsStatus(): {
            status: OperationStatus;
            list: InsuranceInsuredCoverageCriteriaGroup[];
        } {
            const groupsPerRp = this.$store.state.decisioningCriteria.insuredCoverageCriteriaGroups[this.rpName];
            const groups = groupsPerRp && this.selectedInsured ? groupsPerRp[this.selectedInsured.id] : null;
            return groups || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get grantExceptionStatus(): OperationStatus {
            return this.$store.state.insuredDetails.grantExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get removeExceptionStatus(): OperationStatus {
            return this.$store.state.insuredDetails.removeExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get insuredRequestsStatus():
            Record<string, { status: OperationStatus, requests: InsuranceVerificationRequest[] }> {
            return this.$store.state.dashboard.requests[this.rpName]?.requests || {};
        }

        private get insuredSubmissionLinksStatus():
            Record<string, { status: OperationStatus, submissionLink: string }> {
            return this.$store.state.dashboard.submissionLinks[this.rpName]?.submissionLinks || {};
        }

        private get requestsConfigStatus(): {
            status: OperationStatus;
            data: InsuranceRequestsConfig | null;
        } {
            return this.$store.state.dashboard.requestsConfig[this.rpName] || createEmptyRequestsConfigStatus();
        }

        private get submissionLinkPerInsuredId(): Record<string, string> {
            return Object.entries(this.insuredSubmissionLinksStatus).reduce((acc, [ requestId, linkStatus ]) => {
                const insuredId = Object.keys(this.insuredRequestsStatus).find((insuredId) => (
                    this.insuredRequestsStatus[insuredId]?.requests.some((x) => x.requestId === requestId)
                ));
                return insuredId ? { ...acc, [insuredId]: linkStatus.submissionLink } : acc;
            }, {});
        }

        private get coverageCriteriaGroupsStatus(): CoverageCriteriaGroupsStatus {
            return this.$store.state.decisioningCriteria.coverageCriteriaGroups[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get isCollateralEnabled(): boolean {
            return this.requestsConfigStatus.data?.collateralsEvaluationEnabled || false;
        }

        private get alertConfigList(): AlertConfig[] {
            return [ this.alertConfig, this.additionalAlertConfig, this.collectEvidenceAlert, this.errorAlertConfig ]
                .filter(Boolean) as AlertConfig[];
        }

        private get categorizedEnumLabels(): CategorizedEnumLabels {
            return this.$store.state.dashboard.categorizedEnumLabels;
        }

        private changeSorting(sort: Sorting): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, sort: serializeSorting(sort) },
            } as any);
        }

        private async exportInsureds(): Promise<void> {
            await this.$store.actions.exportInsureds.exportInsureds({ rpName: this.rpName });
            if (this.exportStatus === OperationStatus.error) {
                await this.$store.actions.snackbar.displaySnackbar({
                    message: 'Sorry, something went wrong during export. Please try again later.',
                    permanent: true,
                    success: false,
                });
            }
        }

        private async downloadSampleCsv(): Promise<void> {
            downloadSampleCsv(this.insuredFieldsStatus.list, this.coverageTypesStatus.list);
        }

        private showCois(insured: InsuranceInsured): void {
            this.$procedures.execute('showInsuredCois', {
                rpName: this.rpName,
                email: insured.contactEmail,
                coverageModels: this.getCoverageModelsWithCountryLabel(insured),
            });
        }

        private get isInsuredSelected(): boolean {
            return this.$route.query?.insured !== undefined;
        }

        private get selectedInsured(): InsuranceInsured | null {
            const insuredId = this.$route.query?.insured || null;
            return this.insuredsStatus.list.find((insured) => insured.id === insuredId) || null;
        }

        private get insuredLoadingIdList(): string[] {
            return uniq([ ...this.patchInsuredsStatus.currentlyUpdated, ...this.sendRequestStatus.insuredIds ]);
        }

        private showInsuredDetails(insured: InsuranceInsured): void {
            this.hideAlert();
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, insured: insured.id },
            } as any);
        }

        private closeInsuredDetails(): void {
            if (this.isInsuredSelected) {
                this.$router.push({
                    ...this.$route,
                    query: {
                        ...omit(this.$route.query, 'insured'),
                    },
                } as any);
            }
        }

        private openAddIndividualInsuredModal(): void {
            this.$procedures.execute('openAddIndividualInsuredModal', {
                rpName: this.rpName,
            }, this.finishAddInsuredProcedure);
        }

        private openBulkImportInsuredsModal(): void {
            this.$procedures.execute('openBulkImportInsuredsModal', {
                rpName: this.rpName,
            }, this.finishBulkImportInsuredsProcedure);
        }

        private onSendCoiRequestFromInsuredDetails(insureds: InsuranceInsured[]): void {
            // the insureds here will only contain up to 1 insured from insured details
            if (insureds.every(this.isInsuredNoResponse)) {
                this.sendCoiRequestDirectly(insureds, this.onEvaluationUpdated);
            } else {
                this.openSendCoiRequestModal(insureds, this.onEvaluationUpdated);
            }
        }

        private onSendCoiRequestFromInsuredsTable(insureds: InsuranceInsured[]): void {
            // filter out non-qualified insured to only send those when request directly
            // keep original list for the modal in order to display how many insured (qualified or not) selected.
            const coiRequestEnabledInsureds = insureds.filter(shouldEnableCoiRequest);
            coiRequestEnabledInsureds.every(this.isInsuredNoResponse)
                ? this.sendCoiRequestDirectly(coiRequestEnabledInsureds, this.reloadInsureds)
                : this.openSendCoiRequestModal(insureds, this.reloadInsureds);
        }

        private async sendCoiRequestDirectly(
            insureds: InsuranceInsured[],
            finishCallbackFnc?: FinishCoiRequestCallbackFnc,
        ): Promise<void> {
            await this.sendCoiRequest(insureds);
            await this.finishSendCoiRequestProcedure(finishCallbackFnc)(true, insureds);
        }

        private openSendCoiRequestModal(
            insureds: InsuranceInsured[],
            finishCallbackFnc?: FinishCoiRequestCallbackFnc,
        ): void {
            this.$procedures.execute('openSendCoiRequestProcedure', {
                rpName: this.rpName,
                insureds,
            }, this.finishSendCoiRequestProcedure(finishCallbackFnc));
        }

        private async sendCoiRequest(insureds: InsuranceInsured[]): Promise<void> {
            await this.$store.actions.insuredRequest.sendCoiRequest({
                rpName: this.rpName,
                insuredIds: insureds.map((insured) => insured.id),
            });
        }

        private async reloadInsureds(): Promise<void> {
            this.$store.actions.dashboard.clearInsuredsList({ rpName: this.rpName });
            await this.loadNextInsureds();
        }

        private async onEvaluationUpdated(): Promise<void> {
            const insuredId = this.$route.query?.insured || null;
            if (insuredId && typeof insuredId === 'string') {
                await this.$store.actions.dashboard.loadInsured({ rpName: this.rpName, id: insuredId });
                await this.loadInsuredCoverageData({ rpName: this.rpName, insuredId });
            }
        }

        private async updateInsureds(insureds: InsuranceInsuredInput[]): Promise<void> {
            await this.$store.actions.insuredManagement.updateInsureds(
                { rpName: this.rpName, insureds, updateInsuredsListState: true });
        }

        private async patchInsureds(properties: InsuredPatch[]): Promise<void> {
            await this.$store.actions.insuredManagement.patchInsureds({
                rpName: this.rpName,
                isBulk: true,
                patch: properties,
            });
        }

        private finishAddInsuredProcedure = (modified: boolean, collectEvidence?: boolean): void => {
            if (modified) {
                this.reloadInsureds();
                this.loadInsuredStatistics();
                this.setAddInsuredAlert();
                this.clearInsuredsStatus();
                if (collectEvidence) {
                    this.displayCollectEvidenceMessage();
                }
            }
        };
        private finishSendCoiRequestProcedure = (callbackFnc?: FinishCoiRequestCallbackFnc) => async (
            modified: boolean,
            insureds: InsuranceInsured[],
        ): Promise<void> => {
            if (modified) {
                if (this.sendRequestStatus.status === OperationStatus.success) {
                    if (this.sendRequestStatus.failureCount === 0) {
                        this.showAlert(
                            'success',
                            insureds.length === 1
                                ? `Request to ${insureds[0].displayName} was sent.`
                                : `Requests to ${insureds.length} insureds were sent.`,
                        );
                    } else if (this.sendRequestStatus.failureCount > 0) {
                        this.showAlert('warning', `${this.sendRequestStatus.successCount} requests was sent,
                            ${this.sendRequestStatus.failureCount} requests have failed.`);
                    }
                } else {
                    this.showAlert('danger', 'Your request has failed. Please try again.');
                }
            }
            this.$store.actions.insuredRequest.clearSendCoiRequestStatus({ rpName: this.rpName });

            if (callbackFnc) {
                await callbackFnc();
            }
        };

        private finishBulkImportInsuredsProcedure = (
            modified: boolean,
            data: {
                sampleInsureds: InsuranceInsuredInput[];
                collectEvidence?: boolean;
            }): void => {
            const procedureData = data || {};
            if (modified) {
                this.reloadInsureds();
                this.loadInsuredStatistics();
                this.setBulkImportInsuredsAlert(procedureData.sampleInsureds);
                this.clearInsuredsStatus();
                if (procedureData.collectEvidence) {
                    this.displayCollectEvidenceMessage();
                }
            }
        };

        private finishDeactivateInsuredProcedure = (modified: boolean, insureds: InsuranceInsured[]): void => {
            if (modified) {
                this.reloadInsureds();
                this.loadInsuredStatistics();
                this.createInsuredsActionAlert(this.patchInsuredsStatus, insureds, 'deactivated');
                this.clearInsuredsStatus();
            }
        };

        private setAddInsuredAlert(): void {
            if (this.addInsuredsStatus.status === OperationStatus.success) {
                if (this.addInsuredsStatus.successCount === 1) {
                    this.showAlert('success', 'Connected to new Insured');
                } else if (this.updateInsuredsStatus.status === OperationStatus.success
                    && this.updateInsuredsStatus.successCount === 1) {
                    this.showAlert('success', 'Insured updated');
                }
            }
        }

        private displayCollectEvidenceMessage(): void {
            this.setupAlertConfig(
                'highWarning',
                'We’ll collect evidence of insurance from those whose policies had already expired',
                'collectEvidenceAlert',
                'collectEvidenceAlertTimeout',
            );
        }

        private setBulkImportInsuredsAlert(sampleInsureds: InsuranceInsuredInput[]): void {
            if (this.addInsuredsStatus.status === OperationStatus.success) {
                this.configAddSampleAlert(sampleInsureds);
                this.configAddInsuredAlertSuccess();
                if (this.addInsuredsStatus.failedOnRequestCount > 0
                    || this.updateInsuredsStatus.failedOnRequestCount > 0) {
                    this.configAddInsuredErrorAlert();
                }
            }
        }

        private configAddSampleAlert(sampleInsureds: InsuranceInsuredInput[]): void {
            if (sampleInsureds && sampleInsureds.length) {
                const message = this.addInsuredsStatus.totalCount === 0
                    ? 'Attention: All rows of Insured contain sample data. They will be ignored and are not imported'
                    : 'Attention: One or more rows of Insured contain sample data. These rows will be ignored and are not imported';
                this.showAdditionalAlert('highWarning', message);
            }
        }

        private configAddInsuredAlertSuccess(): void {
            const connectionWord = this.addInsuredsStatus.successCount > 1 ? 'connections' : 'connection';
            const insertMessage = this.addInsuredsStatus.successCount > 0
                ? `${this.addInsuredsStatus.successCount} new ${connectionWord} added`
                : '';
            const insuredWord = this.updateInsuredsStatus.successCount > 1 ? 'insureds' : 'insured';
            const updateMessage = this.updateInsuredsStatus.successCount > 0
                ? `${this.updateInsuredsStatus.successCount} ${insuredWord} updated`
                : '';
            const comma = insertMessage && updateMessage ? ', ' : '';
            const finalMessage = `${insertMessage}${comma}${updateMessage}`;
            if (finalMessage) {
                this.showAlert('success', finalMessage);
            }
        }

        private configAddInsuredErrorAlert(): void {
            const insertCount = this.addInsuredsStatus.failedOnRequestCount;
            const updateCount = this.updateInsuredsStatus.failedOnRequestCount;
            const connectionWord = insertCount ? 'connections' : 'connection';
            const insuredWord = updateCount > 0 ? 'insureds' : 'insured';
            const insertMessage = insertCount > 0 ? `${insertCount} ${connectionWord} failed to be connected.` : '';
            const updateMessage = updateCount > 0 ? `${updateCount} ${insuredWord} failed to be updated.` : '';
            const failureMessage = `Due to unknown errors, ${insertMessage} ${updateMessage}`;
            this.errorAlertConfig = {
                type: 'danger',
                title: failureMessage,
            };
            this.errorAlertTimeout = setTimeout(() => {
                this.errorAlertConfig = null;
            }, 5000);
        }

        private clearInsuredsStatus(): void {
            this.$store.actions.insuredManagement.clearAddInsuredsStatus({ rpName: this.rpName });
            this.$store.actions.insuredManagement.clearUpdateInsuredsStatus({ rpName: this.rpName });
            this.$store.actions.insuredManagement.clearPatchInsuredsStatus({ rpName: this.rpName });
        }

        private changeFilters(filters: InsuredFilters): void {
            const { insuredFieldFilters, collateralFieldFilters, ...standardFilters } = filters;
            const sort = this.$route.query?.sort || undefined;
            this.$router.push({
                ...this.$route,
                query: pickBy({
                    sort,
                    ...standardFilters,
                    ...insuredFieldFilters,
                    ...collateralFieldFilters,
                }, Boolean),
            } as any);
        }

        private closeFilters(filters: string[]) {
            this.$router.push({
                ...this.$route,
                query: {
                    ...omit(this.$route.query, filters),
                },
            } as any);
        }

        private onSelectCategory({ changed, value }: { changed: boolean, value: InsuredFilterCategory[] }) {
            const searchFilterOnly = Object.keys(this.filters).length === 1 && this.filters.hasOwnProperty('search');
            const noFilters = Object.keys(this.filters).length === 0;
            if (noFilters || searchFilterOnly || getCategoryByFilters(this.filters)) {
                this.changeCategory({ changed, value });
            } else {
                this.$procedures.execute('confirmFilterOverride', {}, this.onConfirmFilterFinish({ changed, value }));
            }
        }

        private onConfirmFilterFinish({ changed, value }: { changed: boolean, value: InsuredFilterCategory[] }): any {
            return (modified: boolean) => {
                if (modified) {
                    this.changeCategory({ changed, value });
                }
            };
        }

        private changeCategory({ changed, value }: { changed: boolean, value: InsuredFilterCategory[] }): void {
            const sort = this.$route.query?.sort || undefined;
            const search = this.$route.query?.search || undefined;
            const filtersObj = changed ? categoryFilterMap[value[0]] : {};
            this.$router.push({
                ...this.$route,
                query: pickBy({ sort, search, ...filtersObj }, Boolean),
            } as any);
        }

        private async setInsuredsPauseState(
            { insureds, isBulk, value }: { insureds: InsuranceInsured[], isBulk: boolean, value: boolean },
        ): Promise<void> {
            await this.$store.actions.insuredManagement.patchInsureds({
                rpName: this.rpName,
                isBulk,
                patch: insureds.map((insured) => ({
                    id: insured.id,
                    paused: {
                        op: PatchOperationType.replace,
                        newValue: value,
                    },
                }) as InsuredPatch),
            });
            this.createInsuredsActionAlert(this.patchInsuredsStatus, insureds, value ? 'paused' : 'unpaused');
        }

        private deactivateInsureds({ insureds, isBulk }: { insureds: InsuranceInsured[], isBulk: boolean }): void {
            this.$procedures.execute('openDeactivateInsuredProcedure', {
                rpName: this.rpName,
                insureds,
                isBulk,
            }, this.finishDeactivateInsuredProcedure);
        }

        private clearFilters(): void {
            this.changeFilters({});
        }

        private createInsuredsActionAlert(
            status: PatchInsuredsStatus,
            insureds: InsuranceInsured[],
            operation: string,
        ): void {
            if (status.totalCount > 1) {
                this.createBulkInsuredsActionAlert(status, operation);
            } else {
                this.createSingleInsuredActionAlert(status, insureds[0], operation);
            }
        }

        private createSingleInsuredActionAlert(
            status: PatchInsuredsStatus,
            insured: InsuranceInsured,
            operation: string,
        ): void {
            const showAlertFunc = this.selectedInsured && insured.id === this.selectedInsured.id
                ? this.showAlertInInsuredDetails
                : this.showAlert;
            if (status.status === OperationStatus.success) {
                // TODO: revisit to see if successCount has to be 1 here
                if (status.successCount === 1) {
                    showAlertFunc('success', `${insured.contactEmail} was ${operation}`);
                } else {
                    showAlertFunc('danger', `Something went wrong! ${insured.contactEmail} was not ${operation}`);
                }
            } else if (status.status === OperationStatus.error) {
                showAlertFunc(
                    'danger',
                    `Something went wrong! ${insured.contactEmail} was not ${operation}`,
                );
            }
        }

        private createBulkInsuredsActionAlert(
            status: PatchInsuredsStatus,
            operation: string,
        ): void {
            if (status.status === OperationStatus.success) {
                if (status.successCount === 0) {
                    this.showAlert(
                        'danger',
                        `Something went wrong! ${status.failureCount} insureds were not ${operation}`,
                    );
                } else {
                    if (status.successCount === 1) {
                        this.showAlert('success', `${status.successCount} insured was ${operation}`);
                    } else {
                        this.showAlert('success', `${status.successCount} insureds were ${operation}`);
                    }
                    if (status.failureCount > 0) {
                        if (status.failureCount === 1) {
                            this.showAdditionalAlert('highWarning', `Something went wrong! ${status.failureCount} insured was not ${operation}`);
                        } else {
                            this.showAdditionalAlert('highWarning', `Something went wrong! ${status.failureCount} insureds were not ${operation}`);
                        }
                    }
                }
            } else if (status.status === OperationStatus.error) {
                this.showAlert(
                    'danger',
                    `Something went wrong! ${status.failureCount} insureds were not ${operation}`,
                );
            }
        }

        private async onActionsButtonClicked(insuredId: string): Promise<void> {
            const insured = this.insuredsStatus.list.find((insured) => insured.id === insuredId);
            if (!this.submissionLinkPerInsuredId.hasOwnProperty(insuredId) && insured) {
                this.loadingSubmissionLink = true;
                await this.$store.actions.dashboard.loadInsuredRequests({ rpName: this.rpName, insuredId });
                if (this.insuredRequestsStatus.hasOwnProperty(insuredId)
                    && this.insuredRequestsStatus[insuredId].status === OperationStatus.success) {
                    const insuredRequests = [ ...this.insuredRequestsStatus[insuredId].requests ];
                    insuredRequests.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
                    const latestUnsubmitedRequest = insuredRequests
                        .find((request) => request.requestId
                            && request.verificationRequestStatus === 'PENDING_INSURED_SUBMISSION');
                    if (latestUnsubmitedRequest) {
                        await this.$store.actions.dashboard.loadInsuredSubmissionLink(
                            { rpName: this.rpName, requestId: latestUnsubmitedRequest.requestId });
                    }
                }
                this.loadingSubmissionLink = false;
            }
        }

        private copySubmissionLink(submissionLink: string): void {
            navigator.clipboard.writeText(submissionLink);
            this.$store.dispatch('displaySnackbar', {
                success: true,
                message: `Submission link has been copied to clipboard.`,
            });
        }

        private goToCriterion(coverageCriteriaGroupId: string, coverageType: InsuranceCoverageType): void {
            const routeData = this.$router.resolve({
                name: 'insuredDecisioningCriteriaGroup',
                params: {
                    rpId: this.$route.params.rpId,
                    id: coverageCriteriaGroupId,
                },
                query: { coverageType },
            });
            window.open(routeData.href, '_blank');
        }

        private finishGrantExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.grantExceptionStatus === OperationStatus.success) {
                this.showAlert('success', `Exception granted for ${insuredName}`);
            } else {
                this.showAlert('danger', `Failed to grant exception for ${insuredName}`);
            }
        };

        private grantException(insured: InsuranceInsured): void {
            this.$procedures.execute('grantExceptionProcedure', {
                exception: {
                    rpName: this.rpName,
                    insured,
                    data: [ createInsuredLevelException() ],
                },
            }, this.finishGrantExceptionProcedure);
        }

        private finishRemoveExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.removeExceptionStatus === OperationStatus.success) {
                this.showAlert('success', `Exception removed for ${insuredName}`);
            } else {
                this.showAlert('danger', `Failed to remove exception for ${insuredName}`);
            }
        };

        private removeExceptions(insured: InsuranceInsured, exceptionIds: string[]): void {
            this.$procedures.execute('removeExceptionProcedure', {
                rpName: this.rpName,
                insured,
                exceptionIds,
            }, this.finishRemoveExceptionProcedure);
        }

        private openHistoricCoiModal(insured: InsuranceInsured): void {
            this.$procedures.execute('viewHistoricCoiProcedure', {
                rpName: this.rpName,
                insured,
                coverageModels: this.getCoverageModelsWithCountryLabel(insured),
            });
        }

        private resetAlertConfig(configName: AlertConfigName, timeoutName: AlertTimeoutName): void {
            clearTimeout(this[timeoutName]);
            this[configName] = null;
        }

        private setupAlertConfig(
            type: AlertType,
            title: string,
            configName: AlertConfigName,
            timeoutName: AlertTimeoutName,
        ): void {
            this.resetAlertConfig(configName, timeoutName);
            this[configName] = { type, title };
            this[timeoutName] = setTimeout(() => this.resetAlertConfig(configName, timeoutName), 5000);
        }

        private hideAlert(): void {
            this.resetAlertConfig('alertConfig', 'alertConfigTimeout');
        }

        private showAlert(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'alertConfig', 'alertConfigTimeout');
        }

        private showAlertInInsuredDetails(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'insuredDetailsAlertConfig', 'insuredDetailsAlertTimeout');
        }

        private showAdditionalAlert(type: AlertType, title: string): void {
            this.setupAlertConfig(type, title, 'additionalAlertConfig', 'additionalAlertConfigTimeout');
        }

        private isInsuredNoResponse(insured: InsuranceInsured) {
            return insured.verificationStatus === InsuranceVerificationStatus.noInsuredResponse;
        }

        private getCoverageModelsWithCountryLabel(insured: InsuranceInsured): InsuranceCoverageModel[] {
            const groupId =
                sortBy(insured.effectiveGroup, [ (group) => group.displayName.toLowerCase() ])[0]?.id;
            const group = this.coverageCriteriaGroupsStatus.list.find((x) => x.id === groupId);
            const countryCode = group?.countryCode || null;
            return this.coverageModelsStatus.list.map((model) => {
                const label = getCoverageTypeLabelByCountry(
                    model.coverageType, countryCode, this.categorizedEnumLabels,
                );
                return { ...model, label };
            });
        }

        private destroyed(): void {
            this.$store.actions.dashboard.clearInsuredsList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearInsuredFieldsList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearCoverageTypeList({ rpName: this.lastRpName });
            this.$store.actions.dashboard.clearInsuredStatistics({ rpName: this.lastRpName });
            this.$store.actions.dashboardConfiguration.clearDashboardConfiguration({ rpName: this.lastRpName });
            this.$store.actions.insuredDetails.clearCoverageDetailsList({ rpName: this.lastRpName });
            this.$store.actions.insuredDetails.clearCollateralEntities({ rpName: this.lastRpName });
            clearTimeout(this.alertConfigTimeout);
            clearTimeout(this.errorAlertTimeout);
            clearTimeout(this.additionalAlertConfigTimeout);
            clearTimeout(this.collectEvidenceAlertTimeout);
            clearTimeout(this.insuredDetailsAlertTimeout);
        }
    }
</script>
